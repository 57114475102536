import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Button, Panel, Tooltip } from '@crazyegginc/hatch';

import { useMutation, useNotifications, useModal, useHasFeatureFlag } from '/src/hooks';
import { abTestRetireVariantMutation, abTestDeleteVariantMutation } from '../mutations';
import { LazyImg } from '/src/components/LazyImg';
import { Modal } from '/src/contexts/modal';

import { AB_TEST_STATUSES } from '/src/features/ab-testing/constants';
import { AppConfig } from '/src/AppConfig';
import { parseDecimal, preloadImage } from '/src/utils';
import { formatDate, dateDistance } from '/src/utils/date';

import { AbTestRibbon } from '/src/features/ab-testing/components/ab-tests-ribbon';
import { RetireVariantModal } from '/src/features/ab-testing/components/modals/RetireVariant';
import { abTestTypes, abTestVariantTypes } from '../constants';
import { GROUP_BY_TYPES } from '/src/features/recordings/constants';
import { PreviewLink } from '/src/features/ab-testing/components/PreviewLink';

import { ReactComponent as BoltIcon } from '@crazyegginc/hatch/dist/images/icon-bolt-filled.svg';
import { ReactComponent as SnapshotIcon } from '@crazyegginc/hatch/dist/images/icon-camera-filled.svg';
import { ReactComponent as RecordingsIcon } from '@crazyegginc/hatch/dist/images/icon-recording-filled.svg';
import { ReactComponent as SearchIcon } from '@crazyegginc/hatch/dist/images/icon-search-filled.svg';
import { ReactComponent as ImgPlaceholderIcon } from '@crazyegginc/hatch/dist/images/illustration-thumbnail-placeholder.svg';

function AbTestVariantList({ id, site, status, abTest, variant, onAddVariant, onEditVariant }) {
  const notifications = useNotifications();
  const modal = useModal();
  const { mutate: mutateRetireVariant } = useMutation(abTestRetireVariantMutation);
  const { mutate: mutateDeleteVariant } = useMutation(abTestDeleteVariantMutation);
  const hasFunnelFlag = useHasFeatureFlag('funnel');

  // https://app.shortcut.com/crazyegg/story/39345/ab-tests-variant-error-message
  const hasErrors = false; // variant.hasErrors && !variant.isRetired;

  const redirectUrl =
    (abTest.type === abTestTypes.SPLIT || abTest.type === abTestTypes.PATCH) &&
    variant.type === abTestVariantTypes.CONTROL
      ? abTest.matchingUrl
      : abTest.type === abTestTypes.SPLIT
      ? variant.redirectUrl
      : '';

  const isDraft = status === AB_TEST_STATUSES.DRAFT;

  return (
    <li
      className={classNames('relative flex w-full p-6', {
        'border border-california-500': hasErrors && status !== AB_TEST_STATUSES.STOPPED,
        'border-b border-mystic-500': !hasErrors || status === AB_TEST_STATUSES.STOPPED,
      })}
    >
      {(variant.results.hasBestImprovement || variant.type === 'CONTROL') && (
        <AbTestRibbon
          variantType={variant.type}
          hasBestImprovement={variant.results.hasBestImprovement}
          text={variant.type === 'CONTROL' && !variant.results.hasBestImprovement ? 'original' : 'best so far'}
        />
      )}
      {hasErrors && status !== AB_TEST_STATUSES.STOPPED && (
        <>
          <span
            className="absolute left-0 w-full"
            style={{ height: '1px', backgroundColor: 'white', top: '-2px' }}
          ></span>
          <span
            className="absolute flex h-4 w-4 items-center justify-center rounded-full bg-california-500"
            style={{ top: '5px', right: '5px' }}
          >
            <Tooltip
              interactive={true}
              tooltipContent={
                <div className="flex w-[370px] flex-col space-y-2 p-[25px] text-left">
                  <h1 className="text-header-2 m-0 text-white">Variant with errors</h1>
                  <p className="text-body-2 text-white">
                    One or more of the edits in this variant could not be made. To resolve:
                  </p>
                  <ul className="text-body-2 ml-4 list-decimal text-white">
                    <li>
                      <button
                        className="text-link"
                        onClick={() => {
                          modal.show(
                            <RetireVariantModal
                              variant={variant}
                              onConfirm={() => {
                                mutateRetireVariant(
                                  {
                                    id,
                                    variantId: variant.id,
                                  },
                                  {
                                    onSuccess: () => {
                                      modal.close();
                                      notifications.success({
                                        content: `Variant retired successfully.`,
                                        timeout: 3000,
                                      });
                                    },
                                    onError: (error) => {
                                      notifications.error({
                                        content: `Could not retire this variant.`,
                                        timeout: 3000,
                                        context: { error },
                                      });
                                    },
                                  },
                                );
                              }}
                            />,
                          );
                        }}
                      >
                        Retire
                      </button>{' '}
                      this variant.
                    </li>
                    <li>Create a new variant and apply the desired edits.</li>
                    <li>Publish it and let the conversions roll in!</li>
                  </ul>
                </div>
              }
            >
              <BoltIcon aria-label="currently running" className="h-3 w-3 fill-current text-white" />
            </Tooltip>
          </span>
        </>
      )}
      <div className="flex w-full flex-row">
        <div
          className={classNames(
            'group relative mr-6 h-[98px] w-[140px] overflow-hidden rounded border border-mystic-500 bg-mystic-500',
            {
              'cursor-pointer': !!variant.screenshotUrl,
            },
          )}
        >
          <LazyImg
            className="h-[98px] w-[140px] !rounded-none"
            alt="variant thumbnail"
            src={variant?.thumbnailUrl ?? null}
            width="140px"
            height="98px"
            onMouseOver={() => {
              preloadImage(variant.screenshotUrl);
            }}
            Placeholder={ImgPlaceholderIcon}
            onClick={() => {
              if (!variant.screenshotUrl) return;
              modal.show(<VariantScreenshotModal variant={variant} />);
            }}
          />
          {variant.screenshotUrl ? (
            <div className="pointer-events-none absolute bottom-0 right-0 hidden h-10 w-16 items-center justify-center rounded-tl bg-black/40 group-hover:flex">
              <SearchIcon className="h-4 w-4 fill-current text-white" />
            </div>
          ) : null}
        </div>
        <div className="flex flex-1 justify-between" style={{ minWidth: '564px' }}>
          <div>
            <div className="flex items-center space-x-3">
              <h4 className="text-body-1 mb-1 capitalize">{variant.variantName}</h4>
              {redirectUrl ? (
                <a href={redirectUrl} target="_blank" rel="noopener noreferrer" className="text-link mb-1">
                  {redirectUrl}
                </a>
              ) : null}
            </div>
            <p
              className={classNames('text-body-5 mb-2.5', {
                'leading-5': !variant.isRetired,
                'flex items-center leading-none': variant.isRetired,
              })}
              style={{ minWidth: '340px' }}
            >
              {variant.isRetired && (
                <span
                  className="mr-2 flex items-center rounded bg-mystic-500 px-2 font-semibold uppercase"
                  style={{ fontSize: '10px', height: '15px' }}
                >
                  retired
                </span>
              )}
              {variant.type === 'CONTROL'
                ? 'This is your original design and acts as a baseline to compare your other variants against.'
                : `Added ${dateDistance(variant.createdAt)}`}
              {!variant.isRetired && variant.updatedAt !== variant.createdAt
                ? ` • Updated ${dateDistance(variant.updatedAt)}`
                : null}
              {variant.isRetired && variant.retiredAt ? ` • Retired ${formatDate(variant.retiredAt)}` : null}
            </p>
            <ul className="flex items-center">
              {variant.permissions.canRetire && (
                <li className="dotted-item relative mr-4 leading-none">
                  <button
                    className="text-link flex text-xs leading-none"
                    style={{ height: '12px' }}
                    onClick={() => {
                      modal.show(
                        <RetireVariantModal
                          variant={variant}
                          onConfirm={() => {
                            mutateRetireVariant(
                              {
                                id,
                                variantId: variant.id,
                              },
                              {
                                onSuccess: () => {
                                  modal.close();
                                  notifications.success({ content: `Variant retired successfully.`, timeout: 3000 });
                                },
                                onError: (error) => {
                                  notifications.error({
                                    content: `Could not retire this variant.`,
                                    timeout: 3000,
                                    context: { error },
                                  });
                                },
                              },
                            );
                          }}
                        />,
                      );
                    }}
                  >
                    Retire
                  </button>
                  <DotSeparator />
                </li>
              )}

              {isDraft && variant.type !== 'CONTROL' && abTest.variants.length > 2 ? (
                <li className="dotted-item relative mr-4 leading-none">
                  <button
                    className="text-link flex text-xs leading-none"
                    style={{ height: '12px' }}
                    onClick={() => {
                      mutateDeleteVariant(
                        {
                          id,
                          variantId: variant.id,
                        },
                        {
                          onSuccess: () => {
                            modal.close();
                            notifications.success({ content: `Variant deleted successfully.`, timeout: 3000 });
                          },
                          onError: (error) => {
                            notifications.error({
                              content: `Could not delete this variant.`,
                              timeout: 3000,
                              context: { error },
                            });
                          },
                        },
                      );
                    }}
                  >
                    Delete
                  </button>
                  <DotSeparator />
                </li>
              ) : null}

              {variant.permissions.canDuplicate && (
                <li className="dotted-item relative mr-4 leading-none">
                  <button
                    onClick={() => onAddVariant?.(JSON.parse(variant.editorPatch))}
                    className="text-link flex bg-transparent text-xs leading-none"
                    style={{ height: '12px' }}
                  >
                    Duplicate
                  </button>
                  <DotSeparator />
                </li>
              )}

              {variant.permissions.canEdit && (
                <li className="dotted-item relative mr-4 leading-none">
                  <button
                    onClick={() => onEditVariant?.(abTest, variant)}
                    className="text-link flex bg-transparent text-xs leading-none"
                    style={{ height: '12px' }}
                  >
                    Edit
                  </button>
                  <DotSeparator />
                </li>
              )}

              <li className="dotted-item relative mr-4 flex leading-none">
                <PreviewLink variant={variant}>
                  <span className="text-link flex h-[12px] self-center text-xs leading-none">View variant</span>
                </PreviewLink>
                <DotSeparator />
              </li>

              {variant.permissions.canViewSnapshot && variant?.snapshotId && (
                <li className="dotted-item relative mr-4 leading-none">
                  <a
                    href={`${AppConfig.legacyCoreBaseURL()}/snapshots/${variant.snapshotId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-link flex items-center text-xs leading-none"
                    style={{ height: '12px' }}
                  >
                    <SnapshotIcon
                      aria-label="view this snapshot"
                      className="mr-1 h-3 w-3 fill-current text-malibu-500"
                    />
                    View Snapshot
                  </a>
                  <DotSeparator />
                </li>
              )}

              {variant.permissions.canViewRecordings && variant?.snapshotId && (
                <li className="dotted-item relative mr-4 leading-none last:mr-0">
                  <Link
                    to={{
                      pathname: '/recordings',
                      search: `filters={"version":2,"operator":"AND","conditions":[{"criteria":"snapshot","values":[${variant.snapshotId}]}]}&groupBy=${GROUP_BY_TYPES.LIST_VIEW}&site=${site?.name}`,
                    }}
                    className="text-link flex items-center text-xs leading-none"
                    style={{ height: '12px' }}
                  >
                    <RecordingsIcon
                      aria-label="view this recordings"
                      className="mr-1 h-3 w-3 fill-current text-malibu-500"
                    />
                    View Recordings
                  </Link>
                  <DotSeparator />
                </li>
              )}

              {hasFunnelFlag && variant?.resultsId && (
                <li className="dotted-item relative leading-none">
                  <Link
                    to={{
                      pathname: '/funnel',
                      search: `abTestVariationName=${variant.resultsId}&site=${site?.name}`,
                    }}
                    className="text-link flex items-center text-xs leading-none"
                    style={{ height: '12px' }}
                  >
                    Use in Funnel
                  </Link>
                  <DotSeparator />
                </li>
              )}
            </ul>
          </div>
          <ul className="text-body-4 text-right">
            {typeof variant.results.trafficSplit !== 'number' ||
            (variant.results.trafficSplit === 0 && abTest.autoReweight === true) ? (
              <li className="text-body-3 mb-1">traffic split pending</li>
            ) : (
              <li className="mb-1">{`${parseDecimal(variant.results.trafficSplit, 2)}%`} total traffic</li>
            )}
            <li className="mb-1">{variant.results.visitors} visitors</li>
            <li className="mb-1">{variant.results.conversions} conversions</li>
            <li>{`${parseDecimal(variant.results.conversionRate, 2)}%`} conversion rate</li>
          </ul>
        </div>
        <div
          className="ml-4 flex w-[150px] flex-col items-center justify-center border-l border-mystic-500 py-3 pl-4"
          style={{ borderStyle: 'dashed' }}
        >
          <span
            className={classNames('font-light', {
              'text-lima-500': variant.results.improvementFromControl > 0 && !variant.isRetired,
              'text-radical-red-500': variant.results.improvementFromControl < 0 && !variant.isRetired,
              'text-black-pearl-500': !variant.results.improvementFromControl && !variant.isRetired,
              'text-lynch-500': variant.isRetired,
            })}
            style={{ fontSize: '38px' }}
          >
            {variant.results.improvementFromControl
              ? `${parseDecimal(variant.results.improvementFromControl, 2)}%`
              : '--'}
          </span>
          <span className="text-md text-lynch-500">improvement</span>
        </div>
      </div>
    </li>
  );
}

export function AbTestVariantsPanel({
  id,
  site,
  heading = null,
  onAddVariant = null,
  canAddVariants = null,
  onEditVariant = null,
  variants = [],
  status,
  abTest,
}) {
  return (
    <div className="mb-11">
      {heading && <h3 className="text-heading-3 mb-2.5">{heading}</h3>}
      <Panel className="!p-0">
        <ul className="w-full">
          {variants.map((variant) => {
            return (
              <AbTestVariantList
                id={id}
                site={site}
                key={variant.id}
                status={status}
                abTest={abTest}
                variant={variant}
                onAddVariant={onAddVariant}
                onEditVariant={onEditVariant}
              />
            );
          })}
        </ul>
        {canAddVariants ? (
          <div className="flex w-full items-center justify-center bg-white-lilac-500 p-2.5">
            <span className="text-body-5 flex items-center">
              Try adding a new variant to see if you can do even better
              <Button onClick={() => onAddVariant?.()} size="lg" variant="secondary" className="ml-2.5">
                Add another variant
              </Button>
            </span>
          </div>
        ) : null}
      </Panel>
    </div>
  );
}

function DotSeparator() {
  return (
    <span
      className="hide-last-dot absolute block rounded-full bg-dodger-blue-500"
      style={{ width: '2px', height: '2px', top: '0px', bottom: '0px', right: '-8px', margin: 'auto' }}
    />
  );
}

function VariantScreenshotModal({ variant }) {
  const modal = useModal();
  const notifications = useNotifications();

  return (
    <Modal dialogClassName="!w-auto max-w-[90vw] max-h-[90vh] pt-10">
      <img
        src={variant.screenshotUrl}
        alt="screenshot"
        onError={() => {
          notifications.info({
            title: 'Screenshot still processing',
            content: `This variant's screenshot is still being processed. Please check back later.`,
            timeout: 3000,
          });
          modal.close();
        }}
      />
    </Modal>
  );
}
