import { useState, useRef, useMemo, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { Tooltip, Checkbox, DeviceIcon, Button, ReactTable, Divider } from '@crazyegginc/hatch';

import { AppConfig } from '/src/AppConfig';
import { useMutation, usePermissions, useSelection, useNeedsUpgradeToAccessFeature, useModal } from '/src/hooks';
import { useFilter } from '../../snapshots-filter-context';
import { useSnapshotActions } from '../../snapshot-actions-context';

import { LazyImg } from '/src/components/LazyImg';
import { TableRowActionsDropdown } from './TableRowActionsDropdown';
import { DuplicatedTooltip, RefreshedTooltip, ErrorTooltip, StatusTooltips } from './tooltips';
import { RecordingsUpgradeModal } from '/src/features/recordings/components/dashboard/modals/RecordingsUpgradeModal';

import { getSessionQueryParameters } from '/src/utils/url';
import { camelToSnake, snakeToCamel } from '/src/utils/string';
import { formatDate, formatDateTime } from '/src/utils/date';
import { clamp } from '/src/utils/math';

import { ReactComponent as CommentIcon } from '@crazyegginc/hatch/dist/images/icon-comment-outline.svg';
import { ReactComponent as RecordingIcon } from '@crazyegginc/hatch/dist/images/icon-recording-filled.svg';
import { ReactComponent as InfoIcon } from '@crazyegginc/hatch/dist/images/icon-info-circle-outline.svg';
import { ReactComponent as StatusCompleteIcon } from '@crazyegginc/hatch/dist/images/icon-tick-circle-filled.svg';
import { ReactComponent as StatusErrorIcon } from '@crazyegginc/hatch/dist/images/icon-warning-circle-filled.svg';
import { ReactComponent as StatusRunningIcon } from '@crazyegginc/hatch/dist/images/icon-bolt-circle-filled.svg';
import { ReactComponent as StatusScheduledIcon } from '@crazyegginc/hatch/dist/images/icon-clock-circle-filled.svg';
import { ReactComponent as RecurringIcon } from '@crazyegginc/hatch/dist/images/icon-redo.svg';

import { renameSnapshotMutation } from '/src/features/snapshots/mutations';

import { SNAPSHOT_ACTIONS, SNAPSHOT_STATUSES, SNAPSHOT_BASIC_STATUSES } from '/src/features/snapshots/constants.js';
import { FEATURES, SORT_ORDER_TYPES, LOGICALS } from '/src/features/_global/constants';
import { RECORDINGS_FILTER_VERSION } from '/src/features/recordings/constants';
import { gettingStartedFeatureKeys } from '/src/features/getting-started/queries';

export function SnapshotTable({ selectedSiteName, listOfSites, snapshots, fetching, meta, setPollExports }) {
  const [data, setData] = useState([]);
  const filters = useFilter();
  const { currentSelection, removeFromSelection, addToSelection, clearSelection, toggleSelection } = useSelection();
  const tableRef = useRef(null);
  const mockyLoaded = !!(window.Mocky && window.Mocky.getNotificationsBatchCount);
  const needsUpgradeToAccess = useNeedsUpgradeToAccessFeature(FEATURES.RECORDINGS);
  const modal = useModal();

  const tableSortData = useMemo(
    () => ({
      id: snakeToCamel(filters.order.field),
      desc: filters.order.sort === SORT_ORDER_TYPES.DESC,
    }),
    [filters.order.field, filters.order.sort],
  );

  const getSiteName = useCallback(
    (cellRowSiteId) => {
      if (!selectedSiteName) {
        return listOfSites.find((x) => cellRowSiteId === x.id)?.name ?? null;
      }

      return selectedSiteName;
    },
    [listOfSites, selectedSiteName],
  );

  useEffect(() => {
    let aborted = false;

    async function getCommentCounts() {
      const counts = await window.Mocky.getNotificationsBatchCount({
        type: 'comment',
        urls: result.map((snap) => `${AppConfig.legacyCoreBaseURL()}/snapshot/${snap.id}`),
      });

      const snapshotsWithCount = result.map((snap) => ({
        ...snap,
        commentCount: counts.find(
          (x) => x.url === `${AppConfig.legacyCoreBaseURL()}/snapshot/${snap.id}`.replace(/^https?:\/\//, ''),
        )?.count,
      }));

      if (!aborted) {
        setData(snapshotsWithCount);
      }
    }

    let result = snapshots.filter(Boolean);
    setData(result);
    if (mockyLoaded) {
      getCommentCounts();
    }

    return () => {
      aborted = true;
    };
  }, [snapshots, mockyLoaded]);

  const columns = useMemo(() => {
    const cols = [
      {
        header: 'Snapshot',
        accessorKey: 'name',
        cell: function SnapshotTitleCell({ row }) {
          const { name, reoccurring, duration, totalDays, maxVisits, status } = row.original;

          return (
            <>
              <div className="mr-3 min-w-0">
                <RenderSnapshotTitleCell data={row.original} />
              </div>

              <div className="flex space-x-2" data-testid="tooltip-recurring">
                {reoccurring && (
                  <Tooltip
                    style={{ maxWidth: '200px' }}
                    tooltipContent={
                      <div data-testid="tooltip-recurring-content">
                        {status.toUpperCase() === SNAPSHOT_BASIC_STATUSES.ACTIVE
                          ? `This snapshot will auto-renew after ${
                              totalDays - duration
                            } days or ${maxVisits.toLocaleString()} visits`
                          : 'Already reoccurred'}
                      </div>
                    }
                  >
                    <RecurringIcon
                      aria-label="recurring snapshot"
                      className={classNames('h-3.5 w-3.5 rounded-full  fill-current p-[3px] text-white', {
                        'border-dodger-blue-300 bg-dodger-blue-300':
                          status.toUpperCase() === SNAPSHOT_BASIC_STATUSES.ACTIVE,
                        'border-cadet-blue-500 bg-cadet-blue-500':
                          status.toUpperCase() === SNAPSHOT_BASIC_STATUSES.STOPPED,
                      })}
                    />
                  </Tooltip>
                )}

                <Tooltip
                  placement="right"
                  interactive
                  style={{ borderRadius: '6px', padding: '0' }}
                  tooltipContent={
                    <div className="w-[380px] px-4 py-6">
                      <h3 title={name} className="truncate text-left font-semibold">
                        {name}
                      </h3>

                      <div className="my-4 h-[149px] w-full overflow-hidden">
                        <LazyImg
                          className="!h-auto w-full !rounded-none"
                          src={row.original?.thumbnailUrl ?? null}
                          width="138px"
                          height="69px"
                        />
                      </div>

                      <CardContent snapshot={row.original} />
                    </div>
                  }
                >
                  <InfoIcon
                    className="h-3.5 w-3.5 cursor-pointer fill-current text-dodger-blue-300"
                    aria-label="info"
                  />
                </Tooltip>
              </div>
            </>
          );
        },
        meta: {
          align: 'center',
          justify: 'left',
        },
      },
      {
        header: 'Recordings',
        accessorKey: 'id',
        size: 40,
        meta: {
          align: 'center',
        },
        enableSorting: false,
        cell: ({ row }) => {
          return needsUpgradeToAccess ? (
            <button type="button" onClick={() => modal.show(<RecordingsUpgradeModal />)}>
              <RecordingIcon
                className="h-5 w-5 fill-current text-malibu-500"
                aria-label={`link to recordings related to ${row.original.name}`}
              />
            </button>
          ) : (
            <Link
              to={{
                pathname: '/recordings',
                search: `filters=${generateRecordingsFilterUrl(row.original)}&site=${getSiteName(row.original.siteId)}`,
              }}
              className="flex items-center"
            >
              <RecordingIcon
                className="h-5 w-5 fill-current text-malibu-500"
                aria-label={`link to recordings related to ${row.original.name}`}
              />
            </Link>
          );
        },
      },
      {
        header: 'Status',
        accessorKey: 'status', // it's snapshotStatus actually, but the sorting field is called STATUS
        cell: function SnapshotStatusCell({ row }) {
          return <RenderSnapshotStatusCell data={row.original} />;
        },
        size: 60,
        meta: {
          align: 'center',
        },
      },
      {
        header: 'Start date',
        accessorKey: 'startsAt',
        size: 60,
        meta: {
          align: 'center',
        },
        // default to using startsAt instead of createdAt to fix UX for scheduled Snapshots
        cell: function SnapshotCreatedAtCell({ row }) {
          return (
            <div className="flex w-full flex-col items-start">
              <span>{formatDate(row.original.startsAt || row.original.createdAt)}</span>
              {row.original.createdByUserName && (
                <div className="text-caption w-full min-w-0 truncate">
                  <span className="truncate">by {row.original.createdByUserName}</span>
                </div>
              )}
            </div>
          );
        },
      },
      {
        header: () => <div className="mr-5">Visits</div>,
        accessorKey: 'totalVisits',
        size: 60,
        meta: {
          align: 'center',
          justify: 'right',
        },
        cell: function SnapshotVisitsCell({ row }) {
          return <div className="mr-5 w-full text-right">{row.original.totalVisits.toLocaleString()}</div>;
        },
      },
      {
        header: 'Device',
        accessorKey: 'device',
        size: 60,
        meta: {
          align: 'center',
        },
        cell: function SnapshotDeviceCell({ row }) {
          return <DeviceIcon device={row.original.device.toUpperCase()} />;
        },
      },
      {
        header: function SnapshotCommentHeaderCell() {
          return (
            <Tooltip tooltipContent="Comments">
              <CommentIcon className="h-3.5 w-3.5 flex-shrink-0 fill-current" aria-label="comments" />
            </Tooltip>
          );
        },
        id: 'comments',
        size: 40,
        meta: {
          align: 'center',
        },
        cell: function SnapshotCommentCell({ row }) {
          if (row.original.commentCount > 0) {
            return (
              <>
                <a
                  className="text-link underline"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${AppConfig.legacyCoreBaseURL()}/snapshot/${row.original.id}?${getSessionQueryParameters({
                    sidebarOpen: true,
                  })}`}
                >
                  {row.original.commentCount}
                </a>
              </>
            );
          } else {
            return null;
          }
        },
      },
      {
        header: 'Actions',
        minSize: 60,
        maxSize: 60,
        meta: {
          align: 'center',
        },
        cell: ({ row }) => (
          <div>
            <TableRowActionsDropdown entityId={String(row.id)} entity={row.original} setPollExports={setPollExports} />
          </div>
        ),
      },
    ];

    cols.unshift({
      id: 'selection',
      header: () => (
        <div>
          <Checkbox
            checked={currentSelection.length && currentSelection.length === meta.total ? true : false}
            onChange={() => {
              currentSelection.length === meta.total
                ? clearSelection()
                : data.every((x) => currentSelection.find((y) => x.id === y.id))
                ? removeFromSelection([...data])
                : addToSelection([...data]);
            }}
            indeterminate={currentSelection.length > 0 && currentSelection.length < meta.total}
            title="Toggle all rows selected"
            size="lg"
          />
        </div>
      ),
      cell: ({ row }) => (
        <div>
          <Checkbox
            checked={currentSelection.findIndex((x) => x.id === row.original.id) > -1}
            onChange={() => toggleSelection(row.original)}
            size="lg"
          />
        </div>
      ),
      minSize: 50,
      maxSize: 50,
      meta: {
        align: 'center',
      },
    });

    return cols;
  }, [
    getSiteName,
    setPollExports,
    currentSelection,
    meta.total,
    clearSelection,
    data,
    removeFromSelection,
    addToSelection,
    toggleSelection,
    modal,
    needsUpgradeToAccess,
  ]);

  const { setOrder, setSort } = filters;
  const onFetchData = useCallback(
    ({ sorting = null }) => {
      if (sorting) {
        const nextField = camelToSnake(sorting[0].id).toUpperCase();
        setOrder(nextField);
        const nextSort = sorting[0].desc ? SORT_ORDER_TYPES.DESC : SORT_ORDER_TYPES.ASC;
        setSort(nextSort);
      }
    },
    [setOrder, setSort],
  );

  return (
    <ReactTable
      fetching={fetching}
      ref={{ tableRef }}
      rowPadding={false}
      columns={columns}
      data={data}
      onFetchData={onFetchData}
      enableSorting={true}
      sorting={tableSortData}
      useVirtualization={true}
    />
  );
}

function CardContentRow({ heading, content }) {
  return (
    <div className="mb-2.5 flex leading-none">
      <h4 className="text-body-4 w-[99px] flex-shrink-0 pr-3.5 text-right text-cadet-blue-500">{heading}</h4>
      {/* https://github.com/tailwindlabs/tailwindcss/issues/835 */}
      <span className="w-full break-words text-left" style={{ wordBreak: 'break-word' }}>
        {content}
      </span>
    </div>
  );
}

function CardContent({ snapshot }) {
  const permissions = usePermissions();
  const canView = permissions.can('view', snapshot).allowed;
  const hasStarted = snapshot.duration >= 0;
  const isError = snapshot.snapshotStatus === SNAPSHOT_STATUSES.ERROR;

  return (
    <>
      <div className="flex flex-col">
        <CardContentRow
          heading="Site URL"
          content={
            <>
              <a className="text-link text-xs" href={snapshot.sourceUrl} target="_blank" rel="noopener noreferrer">
                {snapshot.sourceUrl}
              </a>{' '}
              &#40;{snapshot.device}&#41;
            </>
          }
        />
        <CardContentRow
          heading="Visits"
          content={
            <>
              {snapshot.totalVisits.toLocaleString()} of {snapshot.maxVisits.toLocaleString()} visits completed
            </>
          }
        />
        <CardContentRow
          heading="Duration"
          content={
            <>
              running for {hasStarted ? clamp(snapshot.duration, 0) : '-'} of {snapshot.totalDays} days
            </>
          }
        />
        <CardContentRow heading="Created" content={formatDate(snapshot.startsAt || snapshot.createdAt)} />
        <CardContentRow
          heading={
            <>
              1<sup>st</sup> visit tracked
            </>
          }
          content={snapshot.firstEvent ? formatDateTime(snapshot.firstEvent) : '-'}
        />
        {snapshot.snapshotStatus === SNAPSHOT_STATUSES.COMPLETED ? (
          <CardContentRow heading="End date" content={snapshot.endedAt ? formatDateTime(snapshot.endedAt) : 'N/A'} />
        ) : null}
        <CardContentRow heading="ID" content={snapshot.id} />
        {snapshot.manualScreenshot === true ? <CardContentRow heading="Captured with" content="Page Camera" /> : null}
      </div>
      {canView && !isError && (
        <>
          <Divider className="-mx-4 my-4 !w-auto !border-t-mako-500" />
          <div className="flex justify-center">
            <Button
              theme="dark"
              onClick={() =>
                window.open(
                  `${AppConfig.legacyCoreBaseURL()}/snapshots/${snapshot.id}?${getSessionQueryParameters()}`,
                  '_blank',
                )
              }
            >
              View Results
            </Button>
          </div>
        </>
      )}
    </>
  );
}

function SnapshotStatusIcon({ snapshot }) {
  const actions = useSnapshotActions();

  let Icon, text;
  let className = '';
  switch (snapshot.snapshotStatus) {
    case SNAPSHOT_STATUSES.ERROR:
      return (
        <div
          tabIndex={0}
          role="button"
          style={{ position: 'relative', outline: 'none' }}
          onMouseEnter={() => actions.showError(snapshot.id)}
        >
          {actions?.lastAction?.ids?.includes(snapshot.id) &&
          actions.lastAction.action === SNAPSHOT_ACTIONS.SHOW_ERROR ? (
            <ErrorTooltip snapshot={snapshot} canHover={true}>
              <StatusErrorIcon className="h-4 w-4 fill-current text-carnation-500" aria-label="snapshot error" />
            </ErrorTooltip>
          ) : (
            <StatusErrorIcon className="h-4 w-4 fill-current text-carnation-500" aria-label="snapshot error" />
          )}
        </div>
      );
    case SNAPSHOT_STATUSES.SCHEDULED:
      Icon = StatusScheduledIcon;
      text = 'Scheduled';
      className = 'text-dandelion-500';
      break;
    case SNAPSHOT_STATUSES.RUNNING:
      Icon = StatusRunningIcon;
      text = 'Running';
      className = 'text-dodger-blue-500';
      break;
    case SNAPSHOT_STATUSES.COMPLETED:
      Icon = StatusCompleteIcon;
      text = 'Completed';
      className = 'text-lima-500';
      break;
    default:
      return '-';
  }
  return (
    <Tooltip tooltipContent={text}>
      <Icon className={classNames('h-4 w-4 fill-current', className)} aria-label={`snapshot ${text.toLowerCase()}`} />
    </Tooltip>
  );
}

function RenderSnapshotTitleCell({ data }) {
  const permissions = usePermissions();
  const actions = useSnapshotActions();
  const renameSnapshot = useMutation(renameSnapshotMutation);
  const queryClient = useQueryClient();

  const renderName = () => {
    if (permissions.can('view', data).allowed) {
      return (
        <a
          className="text-link block truncate underline"
          target="_blank"
          rel="noopener noreferrer"
          href={`${AppConfig.legacyCoreBaseURL()}/snapshots/${data.id}?${getSessionQueryParameters()}`}
          onClick={() => {
            setTimeout(() => queryClient.invalidateQueries({ ...gettingStartedFeatureKeys.gettingStarted }), 5000);
            return true;
          }}
        >
          {data.name}
        </a>
      );
    }
    return <div className="truncate">{data.name}</div>;
  };

  if (data.snapshotStatus === SNAPSHOT_STATUSES.ERROR) {
    return (
      <span className="truncate">
        <ErrorTooltip snapshot={data} canHover={true}>
          <div className="truncate text-mako-500">{data.name}</div>
        </ErrorTooltip>
      </span>
    );
  }

  // the action modals are only shown if a single Snapshot was actioned
  if (
    [SNAPSHOT_ACTIONS.DUPLICATED, SNAPSHOT_ACTIONS.REFRESHED].includes(actions.lastAction.action) &&
    actions.lastAction.ids.includes(data.id) &&
    actions.lastAction.ids.length === 1
  ) {
    if (actions.lastAction.action === SNAPSHOT_ACTIONS.DUPLICATED) {
      return (
        <>
          <DuplicatedTooltip
            fetching={renameSnapshot.isLoading}
            snapshot={data}
            onAction={(name) => {
              renameSnapshot.mutate(
                {
                  id: data.id,
                  name,
                },
                { onSettled: () => actions.clear() },
              );
            }}
            onDismiss={actions.clear}
          >
            {renderName()}
          </DuplicatedTooltip>
        </>
      );
    }
    if (actions.lastAction.action === SNAPSHOT_ACTIONS.REFRESHED) {
      return (
        <>
          <RefreshedTooltip onDismiss={actions.clear}>{renderName()}</RefreshedTooltip>
        </>
      );
    }
  }

  if (!data.hasData && [SNAPSHOT_STATUSES.RUNNING, SNAPSHOT_STATUSES.COMPLETED].includes(data.snapshotStatus)) {
    return (
      <Tooltip
        interactive
        tooltipContent={
          <div>
            No data has been tracked, please{' '}
            <Link
              className="text-link"
              to={{
                pathname: `/options/check-script`,
                search: `?url=${encodeURIComponent(data.sourceUrl)}&device=${data.device.toUpperCase()}`,
              }}
            >
              check your installation
            </Link>
            .
          </div>
        }
      >
        {renderName()}
      </Tooltip>
    );
  }

  return renderName();
}

function RenderSnapshotStatusCell({ data }) {
  const actions = useSnapshotActions();

  const icon = <SnapshotStatusIcon snapshot={data} />;

  if (actions.lastAction.ids && actions.lastAction.ids.length > 1) {
    return icon;
  }

  if (
    [SNAPSHOT_ACTIONS.RESUMED, SNAPSHOT_ACTIONS.PAUSED].includes(actions.lastAction.action) &&
    actions.lastAction.ids.includes(data.id)
  ) {
    return (
      <StatusTooltips action={actions.lastAction.action} onDismiss={actions.clear}>
        {icon}
      </StatusTooltips>
    );
  }

  return icon;
}

function generateRecordingsFilterUrl(data) {
  return JSON.stringify({
    version: RECORDINGS_FILTER_VERSION,
    operator: LOGICALS.AND,
    conditions: [
      {
        criteria: 'snapshot',
        values: [data.id],
      },
      {
        criteria: 'date_range',
        value: {
          start_date: data.startsAt || data.createdAt,
          end_date: data.endedAt || data.expiresAt,
        },
      },
    ],
  });
}
