/* eslint-disable jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useFormikContext } from 'formik';
import { Input, Spinner } from '@crazyegginc/hatch';

import { useAuthContext, useDebounce, useScrollToAndFocus, useWizard, useSearchParams } from '/src/hooks';
import { WizardHeader } from '/src/components/wizard/legacy/wizard-ui';
import { ensureUrlHasProtocol } from '/src/utils/url';
import { SnapshotSettings } from './SnapshotSettings';
import { checkTitleQuery, checkPermissionQuery } from '/src/features/snapshots/queries';
import { SupportLinks } from '/src/support';

export const generateSiteUrlErrors = (
  checkTitle,
  checkPermission,
  errors,
  formik,
  index,
  isBulk,
  accountOwnerEmail,
) => {
  const hasPermission =
    !checkTitle?.permissionDenied &&
    !checkPermission?.permissionDenied &&
    errors?.find?.((error) => error?.permission_denied === true) === undefined;
  const domain = errors?.[0]?.domain || checkTitle?.domain;

  const siteUrlValue = formik.values.snapshots[index].siteUrl;
  const siteUrlTouched = formik.values.pageCameraSnapshot || formik.touched.snapshots?.[index]?.siteUrl;
  const siteUrlError = formik.errors.snapshots?.[index]?.siteUrl;

  const validatedUrlValue = formik.values.snapshots[index].validatedUrl;

  if (siteUrlTouched && siteUrlError) {
    return siteUrlError;
  }
  if ((siteUrlValue.indexOf(domain) === -1 && domain) || !siteUrlValue) {
    return null;
  }
  if (!hasPermission) {
    return (
      <div>
        You do not have permission to create snapshots for this site. Please, contact the primary Account Owner &#40;
        {accountOwnerEmail}&#41;.
      </div>
    );
  }

  if (errors?.find((error) => error?.message === 'Invalid URL')) {
    let tempUrl = '';
    try {
      tempUrl = new URL(siteUrlValue.startsWith('http') ? siteUrlValue : `http://${siteUrlValue}`);
      tempUrl.hash = ''; // wildcard is allowed in the hash
    } catch {
      // noop
    }

    if (tempUrl.toString().match(/\*/)) {
      return (
        <div>
          We noticed <span className="font-semibold">{siteUrlValue}</span> has a wildcard in the URL.{' '}
          {isBulk ? (
            <>
              Please use the <span className="font-semibold">Snapshot for single page</span> option above and select
            </>
          ) : (
            'Please enter the URL of one page you want to track, and then select'
          )}{' '}
          <span className="font-semibold">Wildcard Tracking Options</span> under{' '}
          <span className="font-semibold">Advanced Settings</span>.
        </div>
      );
    } else {
      return 'The entered URL is invalid. Please check and try again.';
    }
  }
  if (errors?.find((error) => error?.message === 'Trouble Accessing the Page') && validatedUrlValue !== siteUrlValue) {
    return (
      <div>
        We ran into trouble while checking your page. Please check the URL and try again.
        <br />
        If the url is correct,{' '}
        <button
          type="button"
          className="text-link text-xs"
          onClick={() => {
            formik.setFieldValue(`snapshots[${index}].validatedUrl`, siteUrlValue);
            formik.setFieldTouched(`snapshots[${index}].validatedUrl`, true);
          }}
        >
          click here
        </button>{' '}
        to proceed with creating the snapshot.
      </div>
    );
  }

  return null;
};

function RevertSnapshotUrl({ formik, url, finalUrl, index }) {
  return (
    <span>
      If this is not the correct url, please{' '}
      <span
        className="text-link"
        onClick={() => {
          const requestedUrl = ensureUrlHasProtocol(url, finalUrl);
          formik.setFieldValue(`snapshots.${index}.siteUrl`, requestedUrl);
          formik.setFieldValue(`snapshots.${index}.validatedUrl`, requestedUrl);
        }}
      >
        revert back to the original URL
      </span>
      .
    </span>
  );
}

export const generateSiteUrlWarningText = (errors, checkTitle, formik, index) => {
  const domain = errors?.[0]?.domain || checkTitle?.domain;
  const siteUrlValue = formik.values.snapshots[index].siteUrl;

  if (siteUrlValue.indexOf(domain) === -1) {
    return null;
  }
  if (checkTitle?.isLoginPage) {
    return (
      <div>
        It looks like <strong>{checkTitle?.requestedUrl}</strong> redirects to a login page. To capture a Snapshot of a
        page that sits behind a login wall, use the{' '}
        <a
          className="text-link"
          href={SupportLinks.snapshots.usingPageCamera}
          target="_blank"
          rel="noopener noreferrer"
        >
          Page Camera
        </a>
        .
        <br />
        <br />
        <RevertSnapshotUrl
          formik={formik}
          url={checkTitle?.requestedUrl}
          finalUrl={checkTitle?.finalUrl}
          index={index}
        />
      </div>
    );
  }
  if (checkTitle?.didRedirect) {
    return (
      <div>
        <strong>{checkTitle?.requestedUrl}</strong> redirected to <strong>{checkTitle?.finalUrl}</strong>. We have
        updated your URL accordingly.
        <br />
        <br />
        <RevertSnapshotUrl
          formik={formik}
          url={checkTitle?.requestedUrl}
          finalUrl={checkTitle?.finalUrl}
          index={index}
        />
      </div>
    );
  }
  if (checkTitle?.finalUrl?.match(/^https?:\/\/(.*)\/\//g)) {
    return (
      <div>
        There seems to be an extra <strong>/</strong> in the URL here:{' '}
        <strong>
          {checkTitle?.finalUrl
            .replaceAll('//', ' // ')
            .replace('http: // ', 'http://')
            .replace('https: // ', 'https://')}
        </strong>
        . Please double check the URL to make sure it is the same one that your visitors access, otherwise we won&#39;t
        track the correct URL.
      </div>
    );
  }
  return null;
};

export function SingleSnapshotCreationPage() {
  const formik = useFormikContext();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const {
    set: wizardSet,
    setNextEnabled: wizardSetNextEnabled,
    data: wizardData,
    state: { nextEnabled },
  } = useWizard();
  const { currentAccount } = useAuthContext();
  const accountOwnerEmail = currentAccount.ownerEmail;

  const validationErrors = useMemo(() => location.state?.validationErrors ?? [], [location.state?.validationErrors]);
  const existingId = wizardData?.existingSnapshot?.id ?? null;
  const isEditing = !!existingId;

  const siteUrlValue = formik.values.snapshots[0].siteUrl;
  const siteUrlTouched = formik.values.pageCameraSnapshot || formik.touched.snapshots?.[0]?.siteUrl;
  const siteUrlError = formik.errors.snapshots?.[0]?.siteUrl;

  const snapshotNameValue = formik.values.snapshots[0].name;
  const snapshotNameTouched = formik.values.pageCameraSnapshot || formik.touched.snapshots?.[0]?.name;
  const snapshotNameError = formik.errors.snapshots?.[0]?.name;

  const validatedUrlValue = formik.values.snapshots[0].validatedUrl;

  const isPageCameraSnapshot = searchParams.get('pageCamera') === 'true';

  const debouncedSnapshotUrl = useDebounce(siteUrlValue, 500);

  const {
    data: pageData,
    isFetching: loadingUrlValidation,
    error,
  } = useQuery({
    ...checkTitleQuery({ url: debouncedSnapshotUrl }),
    keepPreviousData: true,
    enabled: Boolean(
      !isEditing &&
        !isPageCameraSnapshot &&
        debouncedSnapshotUrl.length !== 0 &&
        !siteUrlError &&
        debouncedSnapshotUrl === siteUrlValue &&
        validatedUrlValue !== debouncedSnapshotUrl,
    ),
  });

  const { data: permissionData, isFetching: loadingPermissions } = useQuery({
    ...checkPermissionQuery({ url: debouncedSnapshotUrl }),
    keepPreviousData: true,
    enabled:
      isPageCameraSnapshot &&
      !isEditing &&
      debouncedSnapshotUrl.length !== 0 &&
      !siteUrlError &&
      debouncedSnapshotUrl === siteUrlValue &&
      validatedUrlValue !== debouncedSnapshotUrl,
  });

  const errors = error?.graphQLErrors.map((error) => error.originalError);
  const ref = useScrollToAndFocus({ offset: -80, enabled: !isEditing });

  useEffect(() => {
    wizardSet({
      title: isEditing ? 'Edit Snapshot' : 'Create new Snapshot',
      currentStep: 1,
      totalSteps: 2,
      next: isEditing ? `/snapshots/edit/${existingId}/review` : '/snapshots/new/review',
      nextEnabled: isEditing || isPageCameraSnapshot, // by default editing should always be enabled to continue
      nextText: 'Review',
      loading: false,
      pageCamera: isPageCameraSnapshot ? true : undefined,
      preUpload: isPageCameraSnapshot ? true : undefined,
    });
  }, [existingId, isEditing, location, wizardSet, isPageCameraSnapshot]);

  const { setFieldValue, setFieldTouched, setFieldError, validateForm } = formik;

  useEffect(() => {
    if (isPageCameraSnapshot) {
      validateForm();
    }
  }, [validateForm, isPageCameraSnapshot]);

  useEffect(() => {
    if (loadingUrlValidation || !pageData || !pageData.checkTitle) return;
    if (pageData.checkTitle?.finalUrl) {
      setFieldValue('snapshots[0].siteUrl', pageData.checkTitle.finalUrl);
      setFieldTouched('snapshots[0].siteUrl', true, false);
      setFieldValue('snapshots[0].validatedUrl', pageData.checkTitle.finalUrl);
      setFieldTouched('snapshots[0].validatedUrl', true);
      if (!snapshotNameTouched && snapshotNameValue.length === 0 && pageData.checkTitle?.pageTitle) {
        setFieldValue('snapshots[0].name', pageData.checkTitle.pageTitle);
        setFieldTouched('snapshots[0].name', true);
      }
    }
  }, [pageData, loadingUrlValidation, snapshotNameTouched, snapshotNameValue, setFieldValue, setFieldTouched]);

  useEffect(() => {
    if (validationErrors?.find((error) => error.field === 'siteUrl' && error.message === 'required')) {
      setFieldError('snapshots[0].siteUrl', 'Please provide a Snapshot URL.');
    } else if (validationErrors?.find((error) => error.field === 'siteUrl' && error.message === 'invalid_url')) {
      setFieldError('snapshots[0].siteUrl', 'Your URL appears to be invalid.');
    } else if (validationErrors?.find((error) => error.field === 'siteUrl' && error.message === 'too_long')) {
      setFieldError('snapshots[0].siteUrl', 'Your Snapshot URL is too long, please review it and try again.');
    } else if (validationErrors?.find((error) => error.field === 'siteUrl' && error.message === 'denied')) {
      setFieldError(
        'snapshots[0].siteUrl',
        `You do not have permission to create snapshots for this site. Please, contact the primary Account Owner (${accountOwnerEmail})`,
      );
    }

    if (validationErrors?.find((error) => error.field === 'name' && error.message === 'required')) {
      setFieldError('snapshots[0].name', 'Please provide a name for your Snapshot.');
    }
  }, [validationErrors, setFieldError, accountOwnerEmail]);

  function restartFlow() {
    window.location.replace('/snapshots/new');
  }

  const getSiteUrlErrors = () => {
    return generateSiteUrlErrors(
      pageData?.checkTitle,
      permissionData?.checkPermission,
      errors,
      formik,
      0,
      false,
      accountOwnerEmail,
    );
  };

  const getSiteUrlWarningText = () => {
    return generateSiteUrlWarningText(errors, pageData?.checkTitle, formik, 0);
  };

  const newNextEnabled =
    formik.isValid &&
    (siteUrlTouched || isEditing || isPageCameraSnapshot) &&
    (snapshotNameTouched || isEditing || isPageCameraSnapshot) &&
    getSiteUrlErrors() === null;

  useEffect(() => {
    if (newNextEnabled !== nextEnabled) {
      wizardSetNextEnabled(newNextEnabled);
    }
  }, [nextEnabled, newNextEnabled, wizardSetNextEnabled]);

  return (
    <section className="flex flex-col">
      {isEditing ? <WizardHeader>Let’s edit your Snapshot!</WizardHeader> : null}
      <h2 className="text-header-2 mb-5" id="your-page">
        Your page
      </h2>
      <div className="relative w-full">
        <Input
          ref={ref}
          id="url-input"
          name="snapshots[0].siteUrl"
          label="Enter URL:"
          placeholder="e.g. www.example.com"
          value={siteUrlValue}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          disabled={isEditing || isPageCameraSnapshot}
          validationIcon={
            getSiteUrlErrors() !== null && !isEditing && !loadingUrlValidation && !loadingPermissions
              ? false
              : getSiteUrlErrors() === null &&
                siteUrlTouched &&
                !isEditing &&
                !loadingUrlValidation &&
                !loadingPermissions
              ? true
              : null
          }
          className="!h-[50px] !pr-10"
          error={getSiteUrlErrors()}
          rightIcon={
            loadingUrlValidation || loadingPermissions ? <Spinner className="h-5 w-5 text-cadet-blue-500" /> : null
          }
        />
      </div>

      {isEditing && (
        <div className="text-body-3 mt-1">
          You cannot edit the URL of an already created Snapshot. You can{' '}
          <button type="button" className="text-link italic" onClick={() => restartFlow()}>
            create a new Snapshot
          </button>
          .
        </div>
      )}

      {!!getSiteUrlWarningText() && (
        <div className="text-body-2 mb-2 mt-2 w-full rounded border border-carnation-500 p-3">
          {getSiteUrlWarningText()}
        </div>
      )}

      <div className="relative mt-5 w-full">
        <Input
          id="name-input"
          name="snapshots[0].name"
          label="Name your Snapshot:"
          placeholder="e.g. Homepage"
          maxLength={140}
          value={snapshotNameValue}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          validationIcon={
            snapshotNameTouched && snapshotNameError ? false : snapshotNameTouched && !snapshotNameError ? true : null
          }
          className="!h-[50px] !pr-10"
          error={snapshotNameTouched && snapshotNameError ? snapshotNameError : null}
        />
        <div className="text-caption absolute right-1 top-[26px] font-semibold">{140 - snapshotNameValue.length}</div>
      </div>

      <SnapshotSettings />
    </section>
  );
}
