import { createMachine, assign } from 'xstate';
import * as yup from 'yup';
import { capitalize } from '@crazyegginc/hatch';

import { LOGICALS } from '/src/features/_global/constants';
import {
  GOAL_TRIGGER_TYPES,
  PIXEL_API_LIST,
  ENTER_YOUR_OWN_EVENT,
  ANY_PIXEL_ID,
  GOAL_PURPOSES,
  ELEMENT_SELECTOR_TYPES,
  GOAL_TRACKING_OPTIONS,
  GOAL_UI_MODES,
  GOAL_VALUE_TYPES,
} from '../constants';

import { contextToArgs, parseTriggers } from '/src/features/goals/utils';
import { normalizeUrl } from '/src/utils/url';
import { superLenientUrlRegex, noAsteriskRegex } from '/src/utils/regex';
import { getValidationErrors, isValidCssSelector } from '/src/utils';
import { emptyAsNull } from '/src/utils/string';

import { changeInteractiveMode, changePopupsState } from '/src/services/web-editor';
import { getPixelEventOptions } from '../components/triggers/Pixel';

const { CLICK, FORM_SUBMISSION, URL_VISIT, CODE, PIXEL_EVENT } = GOAL_TRIGGER_TYPES;

/*const goalDetailSchema = yup.object().shape({
  name: yup.string(),
  purpose: yup.string().oneOf([GOAL_PURPOSES.MORE_PAGE_VIEWS]),
  siteId: yup.number().integer().positive(),
  siteName: yup.string(),
});*/

const triggerSchema = yup.object().shape({
  autoName: yup.string(),
  name: yup.string(),
  trigger: yup.string().oneOf([...Object.values(GOAL_TRIGGER_TYPES)]),
  code: yup.string().when('trigger', {
    is: CODE,
    then: yup.string().required(),
    otherwise: yup.string().notRequired(),
  }),
  targetUrl: yup.string().when('trigger', {
    is: (value) => [PIXEL_EVENT, CODE].includes(value),
    then: yup.string().notRequired(),
    otherwise: (schema) => {
      return schema.when('trigger', {
        is: URL_VISIT,
        then: (innerSchema) => {
          const params = new URLSearchParams(window.location.search);
          const selectedSite = params.get('site');
          return innerSchema
            .required('The URL is required.')
            .matches(superLenientUrlRegex, 'This URL is not valid. Please correct it and try again.')
            .matches(
              new RegExp(`${selectedSite.replace(/\./g, '\\.')}`, 'i'),
              `The provided URL must be on ${selectedSite}.`,
            );
        },
        otherwise: (innerSchema) => {
          const params = new URLSearchParams(window.location.search);
          const selectedSite = params.get('site');
          return innerSchema
            .required('The URL is required')
            .matches(superLenientUrlRegex, 'This URL is not valid. Please correct it and try again.')
            .matches(
              new RegExp(`${selectedSite.replace(/\./g, '\\.')}`, 'i'),
              `The provided URL must be on ${selectedSite}.`,
            )
            .matches(
              noAsteriskRegex,
              'Please enter a URL without a wildcard. You can select the wildcard option below.',
            );
        },
      });
    },
  }),
  wildcardUrl: yup.string().when('trigger', {
    is: (value) => [URL_VISIT, CODE, PIXEL_EVENT].includes(value),
    then: yup.string().optional().nullable(),
    otherwise: (innerSchema) => {
      const params = new URLSearchParams(window.location.search);
      const selectedSite = params.get('site');
      return innerSchema.when('trackingOption', {
        is: GOAL_TRACKING_OPTIONS.WILDCARD,
        then: yup
          .string()
          .required('The wildcard URL is a required field.')
          .matches(
            new RegExp(`${selectedSite.replace(/\./g, '\\.')}`, 'i'),
            `The provided URL must be on ${selectedSite}.`,
          )
          .matches(new RegExp('\\*'), 'The wildcard URL must contain at least one wildcard segment denoted by "*".'),
        otherwise: yup.string().optional().nullable(),
      });
    },
  }),
  trackingOption: yup.string().when('trigger', {
    is: (value) => [CLICK, FORM_SUBMISSION].includes(value),
    then: yup
      .string()
      .oneOf([...Object.values(GOAL_TRACKING_OPTIONS)])
      .required(),
    otherwise: yup.string().notRequired(),
  }),
  elementSelectorType: yup.string().when('trigger', {
    is: (value) => [CLICK, FORM_SUBMISSION].includes(value),
    then: yup.string().oneOf([ELEMENT_SELECTOR_TYPES.SPECIFIC, ELEMENT_SELECTOR_TYPES.MANUAL]).required(),
    otherwise: yup.string().notRequired(),
  }),
  filter: yup.string(),
  uiMode: yup.string().oneOf([...Object.values(GOAL_UI_MODES)]),
  // click and form_submission only checks
  desktopSelector: yup.string().when('trigger', {
    is: (value) => [CLICK, FORM_SUBMISSION].includes(value),
    then: yup
      .string()
      .required('required')
      .max(10000, 'Your CSS selector exceeds the maximum length of 10,000 characters.')
      .test('css-selector', 'Please enter a valid CSS selector.', (value) => isValidCssSelector(value)),
    otherwise: yup.string().notRequired(),
  }),
  tabletSelector: yup.string().when('trigger', {
    is: (value) => [CLICK, FORM_SUBMISSION].includes(value),
    then: yup
      .string()
      .required('required')
      .max(10000, 'Your CSS selector exceeds the maximum length of 10,000 characters.')
      .test('css-selector', 'Please enter a valid CSS selector.', (value) => isValidCssSelector(value)),
    otherwise: yup.string().notRequired(),
  }),
  phoneSelector: yup.string().when('trigger', {
    is: (value) => [CLICK, FORM_SUBMISSION].includes(value),
    then: yup
      .string()
      .required('required')
      .max(10000, 'Your CSS selector exceeds the maximum length of 10,000 characters.')
      .test('css-selector', 'Please enter a valid CSS selector.', (value) => isValidCssSelector(value)),
    otherwise: yup.string().notRequired(),
  }),
  pixelApi: yup.string().when('trigger', {
    is: PIXEL_EVENT,
    then: yup
      .string()
      .oneOf([...Object.values(PIXEL_API_LIST)])
      .required('Please select one.'),
    otherwise: yup.string().notRequired(),
  }),
  pixelId: yup.string().when('trigger', {
    is: PIXEL_EVENT,
    then: yup.string().required('Please enter a value.'),
    otherwise: yup.string().notRequired(),
  }),
  pixelEvent: yup.string().notRequired(),
  pixelCustomEvent: yup.string().when('pixelEvent', {
    is: ENTER_YOUR_OWN_EVENT,
    then: yup.string().required('Please enter a value.'),
    otherwise: yup.string().notRequired(),
  }),
});

const goalSchema = yup.object().shape({
  name: yup.string(),
  purpose: yup.string().oneOf([...Object.values(GOAL_PURPOSES)]),
  siteId: yup.number().integer().positive(),
  triggers: yup.array().of(triggerSchema),
  syncValues: yup.boolean().optional().nullable(),
});

// eslint-disable-next-line
const goalTriggerValueSchema = yup.object().shape({
  valueType: yup.string().oneOf([GOAL_VALUE_TYPES.FIXED, GOAL_VALUE_TYPES.ELEMENT, GOAL_VALUE_TYPES.CODE]).required(),
  fixedValue: yup.object().when('valueType', {
    is: (value) => value === GOAL_VALUE_TYPES.FIXED,
    then: yup
      .object()
      .shape({
        currency: yup.string().oneOf(Intl.supportedValuesOf('currency')).required(),
        worth: yup.string().required(),
      })
      .required(),
    otherwise: yup.object().nullable(true),
  }),
  elementValue: yup.object().when('valueType', {
    is: (value) => value === GOAL_VALUE_TYPES.ELEMENT,
    then: yup.object().shape({
      currency: yup.string().oneOf(Intl.supportedValuesOf('currency')).required(),
      condition: yup
        .object()
        .shape({
          trackingOption: yup.string().required(),
          elementSelectorType: yup.string().required(),
          pageUrl: yup.string().required(),
          wildcardUrl: yup.string().when('trackingOption', {
            is: (value) => value === GOAL_TRACKING_OPTIONS.WILDCARD,
            then: yup.string().required(),
            otherwise: yup.string().nullable(true),
          }),
          // onPage: yup.string().when('trackingOption', {
          //   is: (value) => value === GOAL_TRACKING_OPTIONS.SPECIFIC_PAGE,
          //   then: yup.string().required(),
          // }),
          // onAnyPage: yup.boolean().when('trackingOption', {
          //   is: (value) => value === GOAL_TRACKING_OPTIONS.ANY_PAGE,
          //   then: yup.boolean().required(),
          // }),
          selectorForDesktop: yup.string().required(),
          selectorForTablet: yup.string().required(),
          selectorForPhone: yup.string().required(),
        })
        .required(),
    }),
    otherwise: yup.object().nullable(true),
  }),
});

const valueSchema = yup.object().shape({
  syncValues: yup.boolean(),
  triggers: yup.array().of(goalTriggerValueSchema),
});

const emptyTrigger = {
  autoName: '',
  name: '',
  trigger: '',
  targetUrl: '',
  devices: [],
  trackingOption: GOAL_TRACKING_OPTIONS.SPECIFIC_PAGE,
  elementSelectorType: ELEMENT_SELECTOR_TYPES.SPECIFIC,
  filter: null,
  uiMode: GOAL_UI_MODES.SIMPLE,
  expanded: true,
  syncSelectors: true,
  pixelApi: '',
  pixelId: '',
  pixelEvent: '',
  pixelCustomEvent: '',
  valueType: null,
  fixedValue: null,
  elementValue: null,
};

export const initialGoalContext = {
  step: 1,
  nextText: 'Create goal',
  dirty: false,
  errors: {},
  payload: {
    name: '',
    purpose: GOAL_PURPOSES.MORE_PAGE_VIEWS,
    siteId: null,
    triggers: [emptyTrigger],
    syncValues: true,
  },
  goalId: null,
  hidePopups: false,
  isEditing: false,
};

function getDefaultGoalValue(field, context) {
  if (field.toLowerCase() === 'element') {
    return {
      currency: context.defaultCurrency,
      condition: {
        elementSelectorType: ELEMENT_SELECTOR_TYPES.SPECIFIC,
        trackingOption: GOAL_TRACKING_OPTIONS.SPECIFIC_PAGE,
      },
      syncSelectors: true,
    };
  }
  if (field.toLowerCase() === 'fixed') {
    return {
      currency: context.defaultCurrency,
    };
  }
  return undefined;
}

function isSelectorsSynced(options) {
  return options.desktopSelector === options.tabletSelector && options.desktopSelector === options.phoneSelector;
}

function elementBasedGoalFields(version = 3, options) {
  return {
    name: version === 3 ? options?.name ?? null : undefined,
    auto_name: version === 3 ? options?.autoName ?? null : undefined,
    selector_for_desktop: options.desktopSelector,
    selector_for_tablet: options.tabletSelector,
    selector_for_phone: options.phoneSelector,
    selector_source: options.elementSelectorType.toLowerCase(),
    source_url:
      options.trackingOption === GOAL_TRACKING_OPTIONS.WILDCARD
        ? options.wildcardUrl
          ? normalizeUrl(options.wildcardUrl, { appendSlash: false })
          : ''
        : normalizeUrl(options.targetUrl, { appendSlash: false }),
    preview_url:
      options.trackingOption === GOAL_TRACKING_OPTIONS.WILDCARD
        ? normalizeUrl(options.targetUrl, { appendSlash: false })
        : null,
    sync_selectors: isSelectorsSynced(options),
  };
}

export function serializeTrigger({ trigger, version = 3, ...options }) {
  if (trigger === CLICK || trigger === FORM_SUBMISSION) {
    return JSON.stringify({
      version,
      operator: LOGICALS.OR,
      conditions: [
        {
          criteria: trigger.toLowerCase(),
          multiple_values: 'or',
          values: [
            {
              on_any_page: [GOAL_TRACKING_OPTIONS.ANY_PAGE, GOAL_TRACKING_OPTIONS.WILDCARD].includes(
                options.trackingOption,
              )
                ? true
                : false,
              on_page:
                options.trackingOption === GOAL_TRACKING_OPTIONS.WILDCARD
                  ? options.wildcardUrl
                    ? normalizeUrl(options.wildcardUrl, { appendSlash: false })
                    : ''
                  : normalizeUrl(options.targetUrl, { appendSlash: false }),
              ...(version === 2
                ? { value: { ...elementBasedGoalFields(version, options) } }
                : elementBasedGoalFields(version, options)),
            },
          ],
        },
      ],
    });
  } else if (trigger === URL_VISIT) {
    return JSON.stringify({
      version,
      operator: LOGICALS.OR,
      conditions: [
        {
          criteria: trigger.toLowerCase(),
          multiple_values: 'or',
          values: [options.targetUrl],
        },
      ],
    });
  } else if (trigger === CODE) {
    return JSON.stringify({
      version,
      operator: LOGICALS.OR,
      conditions: [
        {
          criteria: trigger.toLowerCase(),
          multiple_values: 'or',
          values: [options.code],
        },
      ],
    });
  } else if (trigger === PIXEL_EVENT) {
    return JSON.stringify({
      version,
      operator: LOGICALS.OR,
      conditions: [
        {
          criteria: trigger.toLowerCase(),
          multiple_values: 'or',
          values: [
            {
              on_any_page: true,
              api: options.pixelApi,
              id: emptyAsNull(options.pixelId === ANY_PIXEL_ID ? null : options.pixelId),
              event: emptyAsNull(
                options.pixelEvent === ENTER_YOUR_OWN_EVENT ? options.pixelCustomEvent : options.pixelEvent,
              ),
            },
          ],
        },
      ],
    });
  }
}

export function deserializeTrigger(rawFilter) {
  if (typeof rawFilter !== 'string') return rawFilter;
  const filter = JSON.parse(rawFilter);
  const trigger = filter.conditions[0];
  const values = trigger.values[0];
  const criteria = trigger.criteria.toUpperCase();

  if (criteria === CLICK || criteria === FORM_SUBMISSION) {
    return {
      name: values?.name,
      autoName: values?.auto_name,
      trigger: criteria,
      trackingOption:
        values.source_url.indexOf('*') >= 0 && values?.preview_url && values?.on_any_page
          ? GOAL_TRACKING_OPTIONS.WILDCARD
          : values?.on_any_page
          ? GOAL_TRACKING_OPTIONS.ANY_PAGE
          : values?.on_page
          ? GOAL_TRACKING_OPTIONS.SPECIFIC_PAGE
          : undefined,
      desktopSelector: values?.selector_for_desktop,
      tabletSelector: values?.selector_for_tablet,
      phoneSelector: values?.selector_for_phone,
      elementSelectorType: values?.selector_source?.toUpperCase?.(),
      targetUrl: values?.preview_url ? values?.preview_url : values?.source_url,
      wildcardUrl: values?.preview_url ? values?.source_url : null,
    };
  } else if (criteria === URL_VISIT) {
    return {
      trigger: criteria,
      targetUrl: values,
    };
  } else if (criteria === CODE) {
    return {
      trigger: criteria,
      code: values,
    };
  } else if (criteria === PIXEL_EVENT) {
    const isCustom = !getPixelEventOptions(values?.api)
      .map((x) => x.value)
      .includes(values?.event);

    const isEmpty = values?.event === null || values?.event === '';

    return {
      trigger: criteria,
      pixelApi: values?.api ?? '',
      pixelId: values?.id ? values.id : ANY_PIXEL_ID,
      pixelEvent: isCustom && !isEmpty ? ENTER_YOUR_OWN_EVENT : (values?.event ?? '') || '',
      pixelCustomEvent: (isCustom ? values?.event : '') ?? '',
    };
  }
}

export const createGoalMachine = (initialState = 'goalName', initialPayload = {}) => {
  return createMachine(
    {
      id: 'goalWizard',
      initial: initialState,
      predictableActionArguments: true,
      context: {
        ...initialGoalContext,
        payload: {
          ...initialGoalContext.payload,
          ...initialPayload,
        },
      },
      states: {
        initializeEdit: {
          on: {
            GOAL_LOADED: {
              actions: assign({
                payload: (ctx, e) => ({
                  ...e.value,
                  triggers: parseTriggers(e.value.triggers),
                }),
              }),
              target: 'manageTriggers',
              // cond: 'goalDetailValid',
            },
          },
        },
        initializeValue: {
          on: {
            GOAL_LOADED: {
              actions: assign({
                payload: (ctx, e) => ({
                  ...e.value,
                  triggers: parseTriggers(e.value.triggers),
                }),
              }),
              target: 'manageValue',
            },
          },
        },
        goalName: {
          always: [
            {
              target: 'goalSite',
              cond: (ctx) => ctx.payload.name.length > 0,
            },
          ],
          on: {
            CHANGE: {
              target: 'goalSite',
              cond: (ctx, e) => e.value.length > 0,
              actions: [
                assign({
                  dirty: true,
                  payload: (ctx, e) => ({
                    ...ctx.payload,
                    [e.key]: e.value,
                  }),
                }),
              ],
            },
          },
        },
        goalSite: {
          always: [
            {
              target: 'manageTriggers',
              cond: (ctx) => !ctx.payload.siteId && !!ctx.siteId,
              actions: [
                assign({
                  payload: (ctx) => ({
                    ...ctx.payload,
                    siteId: ctx.siteId,
                  }),
                }),
              ],
            },
            {
              target: 'manageTriggers',
              cond: (ctx) => !!ctx.payload.siteId,
            },
          ],
          on: {
            SELECT_SITE: {
              target: 'manageTriggers',
              actions: [
                assign({
                  payload: (ctx, e) => ({
                    ...ctx.payload,
                    siteId: e.site.id,
                  }),
                }),
              ],
            },
          },
        },
        manageTriggers: {
          entry: assign({
            errors: (ctx) => getValidationErrors(goalSchema, ctx.payload),
          }),
          on: {
            CHANGE: [
              {
                target: 'manageTriggers',
                cond: (ctx, e) => e.value.length > 0,
                actions: [
                  assign({
                    dirty: true,
                    payload: (ctx, e) => ({
                      ...ctx.payload,
                      [e.key]: e.value,
                    }),
                  }),
                ],
              },
              // TODO: simplify this
              {
                target: 'goalName',
                actions: [
                  assign({
                    dirty: true,
                    payload: (ctx, e) => ({
                      ...ctx.payload,
                      [e.key]: e.value,
                    }),
                  }),
                ],
              },
            ],
            TOGGLE_TRIGGER: {
              target: 'manageTriggers',
              actions: [
                assign({
                  payload: (ctx, e) => {
                    return {
                      ...ctx.payload,
                      triggers: [
                        ...ctx.payload.triggers.slice(0, e.index).map((t) => ({ ...t, expanded: false })),
                        { ...ctx.payload.triggers[e.index], expanded: !ctx.payload.triggers[e.index].expanded },
                        ...ctx.payload.triggers.slice(e.index + 1).map((t) => ({ ...t, expanded: false })),
                      ],
                    };
                  },
                }),
              ],
            },
            ADD_TRIGGER: {
              target: 'manageTriggers',
              actions: [
                assign({
                  dirty: true,
                  payload: (ctx, e) => {
                    return {
                      ...ctx.payload,
                      triggers: [
                        ...ctx.payload.triggers.slice(0, e.index + 1).map((t) => ({ ...t, expanded: false })),
                        { ...emptyTrigger },
                        ...ctx.payload.triggers.slice(e.index + 1).map((t) => ({ ...t, expanded: false })),
                      ],
                    };
                  },
                }),
              ],
            },
            DELETE_TRIGGER: {
              target: 'manageTriggers',
              actions: [
                assign({
                  dirty: true,
                  payload: (ctx, e) => {
                    return {
                      ...ctx.payload,
                      triggers: [...ctx.payload.triggers.slice(0, e.index), ...ctx.payload.triggers.slice(e.index + 1)],
                    };
                  },
                }),
              ],
            },
            SET_TRIGGER: {
              target: 'manageTriggers',
              actions: [
                assign({
                  dirty: true,
                  payload: (ctx, e) => {
                    const updatedTrigger = {
                      ...emptyTrigger, // always build up from the default values
                      ...ctx.payload.triggers[e.index],
                      [e.key]: e.value,
                    };

                    return {
                      ...ctx.payload,
                      triggers: [
                        ...ctx.payload.triggers.slice(0, e.index),
                        { ...updatedTrigger, filter: serializeTrigger(updatedTrigger) },
                        ...ctx.payload.triggers.slice(e.index + 1),
                      ],
                    };
                  },
                }),
              ],
            },
            SET_SYNC_SELECTORS: {
              target: 'manageTriggers',
              actions: [
                assign({
                  dirty: true,
                  payload: (ctx, e) => {
                    const updatedTrigger = {
                      ...emptyTrigger, // always build up from the default values
                      ...ctx.payload.triggers[e.index],
                      syncSelectors: e.value,
                    };
                    return {
                      ...ctx.payload,
                      triggers: [
                        ...ctx.payload.triggers.slice(0, e.index),
                        { ...updatedTrigger, filter: serializeTrigger(updatedTrigger) },
                        ...ctx.payload.triggers.slice(e.index + 1),
                      ],
                    };
                  },
                }),
              ],
            },
            SET_SELECTOR: {
              target: 'manageTriggers',
              actions: [
                assign({
                  dirty: true,
                  payload: (ctx, e) => {
                    const currentTrigger = ctx.payload.triggers[e.index];
                    const updatedTrigger = {
                      ...currentTrigger,
                    };
                    if (updatedTrigger.syncSelectors === true) {
                      updatedTrigger.phoneSelector = e.value;
                      updatedTrigger.tabletSelector = e.value;
                      updatedTrigger.desktopSelector = e.value;
                    } else if (e.device) {
                      updatedTrigger[`${e.device.toLowerCase()}Selector`] = e.value;
                    }

                    return {
                      ...ctx.payload,
                      triggers: [
                        ...ctx.payload.triggers.slice(0, e.index),
                        { ...updatedTrigger, filter: serializeTrigger(updatedTrigger) },
                        ...ctx.payload.triggers.slice(e.index + 1),
                      ],
                    };
                  },
                }),
              ],
            },
            SET_INTERACTIVE: {
              actions: [
                (ctx, e) => {
                  changeInteractiveMode(e.value, 'element-selector-frame');
                },
              ],
            },
            CHANGE_POPUPS_STATE: {
              actions: [
                assign({
                  hidePopups: (ctx) => !ctx.hidePopups,
                }),
                (ctx) => {
                  changePopupsState(ctx.hidePopups, 'element-selector-frame');
                },
              ],
            },
            NEXT: [
              {
                target: 'savingGoal',
                cond: 'goalValid',
              },
            ],
          },
        },
        manageValue: {
          on: {
            SET_SELECTOR: {
              actions: [
                assign({
                  dirty: true,
                  payload: (ctx, e) => {
                    const index = e.index || 0;
                    const currentTrigger = ctx.payload.triggers[index];
                    const updatedTrigger = {
                      ...currentTrigger,
                    };
                    if (updatedTrigger.elementValue.syncSelectors === true) {
                      updatedTrigger.elementValue.condition.selectorForPhone = e.value;
                      updatedTrigger.elementValue.condition.selectorForTablet = e.value;
                      updatedTrigger.elementValue.condition.selectorForDesktop = e.value;
                    } else if (e.device) {
                      updatedTrigger.elementValue.condition[`selectorFor${capitalize(e.device.toLowerCase())}`] =
                        e.value;
                    }

                    return {
                      ...ctx.payload,
                      triggers: [
                        ...ctx.payload.triggers.slice(0, e.index),
                        { ...updatedTrigger },
                        ...ctx.payload.triggers.slice(e.index + 1),
                      ],
                    };
                  },
                }),
              ],
            },
            SET_SYNC_VALUES: {
              actions: assign({
                payload: (ctx, e) => ({
                  ...ctx.payload,
                  syncValues: e.value,
                }),
              }),
            },
            SET_TRIGGER: {
              actions: assign({
                payload: (ctx, e) => {
                  return {
                    ...ctx.payload,
                    triggers: ctx.payload.triggers.map((trigger, index) => {
                      if (index === e.index || e.index === null) {
                        if (e.key === 'valueType' && !trigger?.[`${e.value.toLowerCase()}Value`]) {
                          const valueKey = `${e.value.toLowerCase()}Value`;
                          return {
                            ...trigger,
                            [e.key]: e.value,
                            [valueKey]: getDefaultGoalValue(e.value, ctx),
                          };
                        }
                        return {
                          ...trigger,
                          [e.key]: e.value,
                        };
                      }
                      return trigger;
                    }),
                  };
                },
              }),
            },
            SET_TRIGGER_VALUE: {
              actions: assign({
                payload: (ctx, e) => {
                  return {
                    ...ctx.payload,
                    triggers: ctx.payload.triggers.map((trigger, index) => {
                      if (index === e.index || e.index === null) {
                        return {
                          ...trigger,
                          [`${e.trigger.valueType.toLowerCase()}Value`]: {
                            ...(trigger[`${e.trigger.valueType.toLowerCase()}Value`] || {}),
                            [e.key]: e.value,
                          },
                        };
                      }
                      return trigger;
                    }),
                  };
                },
              }),
            },
            SET_INTERACTIVE: {
              actions: [
                (ctx, e) => {
                  changeInteractiveMode(e.value, 'element-selector-frame');
                },
              ],
            },
            CHANGE_POPUPS_STATE: {
              actions: [
                assign({
                  hidePopups: (ctx) => !ctx.hidePopups,
                }),
                (ctx) => {
                  changePopupsState(ctx.hidePopups, 'element-selector-frame');
                },
              ],
            },
            BACK: {
              target: 'manageTriggers',
            },
            NEXT: {
              target: 'savingValue',
              cond: 'valueValid',
            },
            SKIP: {
              target: 'completed',
            },
          },
        },
        savingTest: {
          entry: assign({
            nextText: 'Saving draft...',
          }),
          on: {
            SAVE_SUCCESS: {
              target: 'completed',
            },
          },
        },
        savingValue: {
          on: {
            VALUE_SUCCESS: [
              {
                target: 'completed',
              },
            ],
            VALUE_ERROR: [
              {
                target: 'manageValue',
              },
            ],
          },
        },
        savingGoal: {
          on: {
            GOAL_SUCCESS: [
              {
                target: 'manageValue',
                actions: [
                  assign({
                    nextText: (ctx) => ctx.originalNextText,
                    goalId: (ctx, e) => e.goalId,
                  }),
                ],
              },
            ],
            GOAL_ERROR: {
              target: 'errored',
              actions: assign({
                nextText: (ctx) => ctx.originalNextText,
              }),
            },
          },
          after: {
            5000: {
              target: 'timedout',
              actions: assign({
                nextText: (ctx) => ctx.originalNextText,
              }),
            },
          },
        },
        errored: {
          always: [
            {
              target: 'manageValue',
              cond: 'isValueFlow',
            },
            {
              target: 'manageTriggers',
            },
          ],
        },
        timedout: {
          always: [
            {
              target: 'manageValue',
              cond: 'isValueFlow',
            },
            {
              target: 'manageTriggers',
            },
          ],
        },
        completed: {
          type: 'final',
          data: (ctx) => ({
            goalId: ctx.goalId,
            nextPage: ctx.nextPage,
          }),
        },
        cancelled: {
          type: 'final',
          data: (ctx) => {
            return {
              goal: contextToArgs(ctx),
              reason: ctx.cancelReason,
            };
          },
        },
      },
      on: {
        // global actions
        BACK: {
          target: 'cancelled',
          actions: [
            assign({
              cancelReason: 'BACK',
            }),
          ],
        },
        CANCEL: {
          target: 'cancelled',
          actions: [
            assign({
              cancelReason: 'CANCEL',
            }),
          ],
        },
        SELECT_SITE: {
          actions: [
            assign({
              payload: (ctx, e) => ({
                ...ctx.payload,
                siteId: e.site.id,
              }),
            }),
          ],
        },
        CHANGE: {
          actions: [
            assign({
              dirty: true,
              payload: (ctx, e) => ({
                ...ctx.payload,
                [e.key]: e.value,
              }),
            }),
          ],
        },
      },
    },
    {
      guards: {
        /*goalDetailValid: (context) => {
          try {
            goalDetailSchema.validateSync(context.payload);
            return true;
          } catch {
            return false;
          }
        },*/
        goalValid: (context) => {
          try {
            goalSchema.validateSync(context.payload);
            return true;
          } catch (err) {
            return false;
          }
        },
        valueValid: (context) => {
          try {
            valueSchema.validateSync(context.payload);
            return true;
          } catch (err) {
            return false;
          }
        },
        isValueFlow: () => {
          return window.location.pathname.endsWith('edit-value');
        },
      },
    },
  );
};

export const newGoalMachine = createGoalMachine();
export const editGoalMachine = createGoalMachine('initializeEdit');
export const valueMachine = createGoalMachine('initializeValue');
