import { NewAddonButton } from '/src/features/addons/common/NewAddonButton';

import AddonPNG from '@crazyegginc/hatch/dist/images/illustration-survey.png';
import AddonWebP from '@crazyegginc/hatch/dist/images/illustration-survey.webp';

import { Paywall } from '/src/components/Paywall';
import { FEATURES } from '/src/features/_global/constants';
import { useHasFeatureFlag } from '/src/hooks';
import { SurveySuggestions } from './SurveySuggestions';

export function NoSurveysWall({ mini }) {
  const hasSuggestionsFlag = useHasFeatureFlag('shell-addon-suggestions');

  if (!hasSuggestionsFlag) return <NoSurveysWallContent mini={mini} />;

  return <SurveySuggestions mini={mini} />;
}

export function NoSurveysWallContent({ mini }) {
  return (
    <Paywall>
      <Paywall.Image
        webp={AddonWebP}
        fallback={AddonPNG}
        width={mini ? '80px' : '175px'}
        className={mini ? '!mb-3 !mt-2' : ''}
      />
      <Paywall.Title mini={mini}>Create your first Survey!</Paywall.Title>

      <Paywall.Body>
        <p style={{ textWrap: 'balance' }}>
          Connect with your customers and website visitors to learn what makes them tick, what they want more of, and
          how they feel about your service.
        </p>
      </Paywall.Body>
      <NewAddonButton size="base" disableIfNoPermission={true} type={FEATURES.SURVEYS} />
    </Paywall>
  );
}
