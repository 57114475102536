import { useMemo, useState } from 'react';
import classNames from 'classnames';
import { Input, Select } from '@crazyegginc/hatch';
import { SupportLinks } from '/src/support';
import { ReactComponent as AutoAdjustedIcon } from '@crazyegginc/hatch/dist/images/icon-traffic-outline.svg';

import { processNumberInput } from '/src/utils';
import { balanceWeights } from '/src/features/ab-testing/utils';
import { abTestTypes } from '/src/features/ab-testing/constants';

export function VariantWeightsPanel({ abTest, onSelect = null, onChange = null }) {
  // clone abTest.variants to avoid mutating the original array
  const [variants, setVariants] = useState(() => {
    if (abTest.autoReweight === true) {
      return abTest.variants;
    }
    const initialVariants = balanceWeights(
      abTest.variants.map((variant) => ({
        ...variant,
        weight:
          typeof variant.weight === 'number' || abTest.id
            ? variant?.weight ?? 0
            : parseFloat(100 / abTest.variants.length).toFixed(1),
      })),
    ).variants;
    onChange?.(initialVariants);
    return initialVariants;
  });

  const totalWeight = useMemo(() => {
    return balanceWeights(variants).total;
  }, [variants]);

  const value = String(abTest.autoReweight === null ? true : abTest.autoReweight);

  return (
    <>
      <div>
        <Select
          value={value}
          onChange={(value) => {
            const parsedValue = value === 'true';
            if (parsedValue === true) {
              setVariants((variants) => {
                const newVariants = variants.map((variant) => {
                  return {
                    ...variant,
                    weight: undefined,
                  };
                });
                onChange?.(newVariants);
                return newVariants;
              });
            } else {
              setVariants((variants) => {
                const newVariants = balanceWeights(
                  variants.map((variant) => {
                    const existingWeight = abTest.variants.find((v) => v.id === variant.id)?.weight ?? null;
                    return {
                      ...variant,
                      weight:
                        typeof existingWeight === 'number' || abTest.id
                          ? existingWeight
                          : parseFloat(100 / abTest.variants.length).toFixed(1),
                    };
                  }),
                ).variants;
                onChange?.(newVariants);
                return newVariants;
              });
            }
            onSelect?.(parsedValue);
          }}
          options={[
            { label: 'Multi-arm bandit - Powered by AI', value: 'true' },
            { label: 'Manual', value: 'false' },
          ]}
          style={{
            height: '40px',
          }}
        />
        <div className="mb-4 mt-1">
          {abTest.autoReweight === true ? (
            <span className="text-body-5">
              Maximize conversions using{' '}
              <a
                href={SupportLinks.abTests.multiArmBanditMethod}
                target="_blank"
                rel="noopener noreferrer"
                className="text-link"
              >
                multi-arm bandit method
              </a>
              . The traffic split will automatically be readjusted.
            </span>
          ) : (
            <span className="text-body-5">Customize the traffic split.</span>
          )}
        </div>
      </div>

      <div className="flex flex-col divide-y divide-mystic-500 rounded border border-mystic-500">
        {variants.map((variant, index) => (
          <div className="flex min-h-[65px] items-center justify-between px-5 py-3.5" key={`ReviewVariant-${index}`}>
            <div className="flex min-w-0 flex-col">
              {variant.type.toUpperCase() === 'CONTROL' ? (
                <>
                  <span className="text-body-2">Control</span>
                  {abTest.type === abTestTypes.SPLIT ? (
                    <span className="text-body-4">{abTest.url || abTest.pageUrl}</span>
                  ) : null}
                </>
              ) : (
                <>
                  <span className="text-body-2">{variant.variantName ? variant.variantName : `Variant #${index}`}</span>
                  {abTest.type === abTestTypes.SPLIT ? (
                    <span className="text-body-4 truncate">{variant.redirectUrl}</span>
                  ) : null}
                </>
              )}
            </div>
            <div className="flex items-center">
              <div className="h-full py-1.5">
                <div className="h-full w-px bg-mystic-500" />
              </div>
              <div className="flex w-[136px] items-center space-x-2.5 pl-3.5">
                {abTest.autoReweight ? (
                  <>
                    <AutoAdjustedIcon className="h-4 w-4 fill-current text-cadet-blue-500" />
                    <span className="text-body-4">Auto adjusted</span>
                  </>
                ) : (
                  <div className="flex h-[35px] items-center">
                    <div className="w-[58px]">
                      <Input
                        className="rounded-r-none !px-2 !text-center"
                        value={String(variant?.weight ?? '')}
                        onChange={(e) => {
                          setVariants((variants) => {
                            const newVariants = [...variants];
                            newVariants[index].weight = e.target.value;
                            onChange?.(newVariants);
                            return newVariants;
                          });
                        }}
                        onKeyDown={(e) => {
                          processNumberInput(e);
                        }}
                      />
                    </div>
                    <div className="text-body-4 flex h-[35px] w-10 items-center justify-center rounded-r border border-l-0 border-mystic-500 bg-white-lilac-500 text-black-pearl-500">
                      &#37;
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>

      {abTest.autoReweight === false ? (
        <div className="mt-4 flex items-center justify-between">
          {totalWeight !== 100 ? (
            <span className="text-body-2 ml-5 text-carnation-500">
              The total must be within 100&#37; &plusmn; 1&#37;
            </span>
          ) : (
            <span>&nbsp;</span>
          )}
          <span
            className={classNames('mr-[95px]', {
              'font-semibold text-carnation-500': totalWeight !== 100,
            })}
          >
            {parseFloat(totalWeight)
              .toFixed(1)
              .replace(/[.,]0$/, '')}
            &#37;
          </span>
        </div>
      ) : null}
    </>
  );
}
