import { useEffect, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useLocation, useOutletContext } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { Button, Input, Spinner } from '@crazyegginc/hatch';

import { useSearchParams, useAuthContext, useDebounce, useScrollToAndFocus, useWizard } from '/src/hooks';
import { generateInitialSnapshotSchema } from './Wizard';
import { checkTitleQuery } from '/src/features/snapshots/queries';
import { generateSiteUrlErrors, generateSiteUrlWarningText } from './SingleSnapshotCreationPage';

import { ReactComponent as PlusIcon } from '@crazyegginc/hatch/dist/images/icon-plus.svg';

const MAX_BULK_PAGES = 25;

export function BulkSnapshotCreationPage() {
  const [searchParams] = useSearchParams();
  const snapshotFieldArray = useOutletContext();
  const formik = useFormikContext();
  const location = useLocation();
  const { set: wizardSet, setNextEnabled: wizardSetNextEnabled } = useWizard();
  const snapshotsCount = formik.values.snapshots.length;
  const isAbTestSnapshot = searchParams.get('abTest') === 'true';
  const { currentAccount } = useAuthContext();
  const accountOwnerEmail = currentAccount.ownerEmail;

  const fieldArrayPush = snapshotFieldArray.push;

  const validationErrors = useMemo(() => location.state?.validationErrors ?? [], [location.state?.validationErrors]);
  const createSnapshotMultiplier = formik.values.deviceTracking.custom
    ? 1
    : formik.values.deviceTracking.devices.length;

  useEffect(() => {
    if (snapshotsCount < 2) {
      fieldArrayPush(generateInitialSnapshotSchema());
    }
  }, [snapshotsCount, fieldArrayPush]);

  useEffect(() => {
    wizardSet({
      title: 'Create new Snapshots',
      currentStep: 1,
      totalSteps: 3,
      next: `/snapshots/new/settings${location.search}`,
      nextText: 'Settings',
      loading: false,
    });
  }, [location, wizardSet]);

  const { setFieldError } = formik;

  useEffect(() => {
    validationErrors?.forEach((error) => {
      const index = Math.floor(error.index / createSnapshotMultiplier);

      if (error.field === 'siteUrl' && error.message === 'required') {
        setFieldError(`snapshots[${index}].siteUrl`, 'Please provide a Snapshot URL.');
      } else if (error.field === 'siteUrl' && error.message === 'invalid_url') {
        setFieldError(`snapshots[${index}].siteUrl`, 'Your URL appears to be invalid.');
      } else if (error.field === 'siteUrl' && error.message === 'too_long') {
        setFieldError(`snapshots[${index}].siteUrl`, 'Your Snapshot URL is too long, please review it and try again.');
      } else if (error.field === 'siteUrl' && error.message === 'denied') {
        setFieldError(
          `snapshots[${index}].siteUrl`,
          `You do not have permission to create snapshots for this site. Please, contact the primary Account Owner (${accountOwnerEmail})`,
        );
      }

      if (error.field === 'name' && error.message === 'required') {
        setFieldError(`snapshots[${index}].name`, 'Please provide a name for your Snapshot.');
      }
    });
  }, [validationErrors, createSnapshotMultiplier, setFieldError, accountOwnerEmail]);

  const nextEnabled = isAbTestSnapshot
    ? formik.values.snapshots.every(
        (snapshot, index) =>
          !formik.errors.snapshots?.[index]?.siteUrl &&
          !formik.errors.snapshots?.[index]?.name &&
          !formik.errors.snapshots?.[index]?.validatedUrl,
      )
    : formik.values.snapshots.every(
        (snapshot, index) =>
          formik.touched.snapshots?.[index]?.siteUrl &&
          !formik.errors.snapshots?.[index]?.siteUrl &&
          formik.touched.snapshots?.[index]?.name &&
          !formik.errors.snapshots?.[index]?.name &&
          formik.touched.snapshots?.[index]?.validatedUrl &&
          !formik.errors.snapshots?.[index]?.validatedUrl,
      );

  useEffect(() => {
    wizardSetNextEnabled(nextEnabled);
  }, [nextEnabled, wizardSetNextEnabled]);

  function addSnapshot() {
    snapshotFieldArray.push(generateInitialSnapshotSchema());
  }

  return (
    <div className="flex flex-col">
      {formik.values.snapshots.map((snapshot, idx) => (
        <SnapshotRowItem index={idx} key={`Snapshot-${snapshot.uuid}`} snapshotFieldArray={snapshotFieldArray} />
      ))}

      {formik.values.snapshots.length < MAX_BULK_PAGES ? (
        <div className="mt-5 flex items-center">
          <Button onClick={() => addSnapshot()}>
            <PlusIcon className="mr-2 h-2.5 w-2.5 fill-current" />
            Add another Snapshot
          </Button>
          <div className="text-body-5 ml-3">
            You can add {MAX_BULK_PAGES - formik.values.snapshots.length} more pages
          </div>
        </div>
      ) : (
        <div className="text-body-2 mt-7">
          Please finish creating these {MAX_BULK_PAGES} Snapshots before trying to add any more.
        </div>
      )}
    </div>
  );
}

function SnapshotRowItem({ index, snapshotFieldArray }) {
  const formik = useFormikContext();
  const snapshotsCount = formik.values.snapshots.length;

  const siteUrlValue = formik.values.snapshots[index].siteUrl;
  const siteUrlTouched = formik.touched.snapshots?.[index]?.siteUrl;
  const siteUrlError = formik.errors.snapshots?.[index]?.siteUrl;

  const snapshotNameValue = formik.values.snapshots[index].name;
  const snapshotNameTouched = formik.touched.snapshots?.[index]?.name;
  const snapshotNameError = formik.errors.snapshots?.[index]?.name;

  const validatedUrlValue = formik.values.snapshots[index].validatedUrl;

  const debouncedSnapshotUrl = useDebounce(siteUrlValue, 500);
  const ref = useScrollToAndFocus({ offset: -80, enabled: index === 0 });
  const { currentAccount } = useAuthContext();
  const accountOwnerEmail = currentAccount.ownerEmail;

  const {
    data: pageData,
    isFetching: loadingUrlValidation,
    error,
  } = useQuery({
    ...checkTitleQuery({ url: debouncedSnapshotUrl }),
    keepPreviousData: true,
    enabled: Boolean(
      debouncedSnapshotUrl.length !== 0 &&
        !siteUrlError &&
        debouncedSnapshotUrl === siteUrlValue &&
        validatedUrlValue !== debouncedSnapshotUrl,
    ),
  });

  const { setFieldValue, setFieldTouched } = formik;

  useEffect(() => {
    if (!loadingUrlValidation && pageData?.checkTitle?.finalUrl) {
      setFieldValue(`snapshots[${index}].siteUrl`, pageData.checkTitle.finalUrl);
      setFieldTouched(`snapshots[${index}].siteUrl`, true, false);
      setFieldValue(`snapshots[${index}].validatedUrl`, pageData.checkTitle.finalUrl);
      setFieldTouched(`snapshots[${index}].validatedUrl`, true);
      if (!snapshotNameTouched && snapshotNameValue.length === 0 && pageData.checkTitle?.pageTitle) {
        setFieldValue(`snapshots[${index}].name`, pageData.checkTitle.pageTitle);
        setFieldTouched(`snapshots[${index}].name`, true);
      }
    }
  }, [pageData, loadingUrlValidation, snapshotNameTouched, snapshotNameValue, setFieldValue, setFieldTouched, index]);

  function removeSnapshot() {
    snapshotFieldArray.remove(index);
  }

  const errors = error?.graphQLErrors.map((error) => error.originalError);

  const getSiteUrlErrors = () => {
    return generateSiteUrlErrors(pageData?.checkTitle, null, errors, formik, index, true, accountOwnerEmail);
  };

  const getSiteUrlWarningText = () => {
    return generateSiteUrlWarningText(errors, pageData?.checkTitle, formik, index);
  };

  return (
    <>
      <div
        className="relative mt-5 flex w-full max-w-[720px] rounded border border-mystic-500 bg-white-lilac-500"
        data-testid="page_item"
      >
        <div className="flex w-[70px] flex-col items-center justify-center rounded-l border-r border-mystic-500 bg-white">
          <div className="text-header-1">{index + 1}</div>
          {index >= 2 && <div className="text-body-4">(optional)</div>}
        </div>
        <div className="flex flex-1 flex-col items-end px-6 py-5">
          <div className="relative w-full">
            <Input
              ref={ref}
              id={`url-input-${index}`}
              name={`snapshots[${index}].siteUrl`}
              label="Enter URL:"
              placeholder="e.g. www.example.com"
              value={siteUrlValue}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              validationIcon={
                getSiteUrlErrors() !== null && !loadingUrlValidation
                  ? false
                  : getSiteUrlErrors() === null && siteUrlTouched && !loadingUrlValidation
                  ? true
                  : null
              }
              className="!h-[50px] !pr-10"
              error={getSiteUrlErrors()}
              rightIcon={loadingUrlValidation ? <Spinner className="h-5 w-5 text-cadet-blue-500" /> : null}
            />
          </div>

          {!!getSiteUrlWarningText() && (
            <div className="text-body-2 mb-2 mt-2 w-full rounded border border-carnation-500 p-3">
              {getSiteUrlWarningText()}
            </div>
          )}

          <div className="relative mt-5 w-full">
            <Input
              id="name-input"
              name={`snapshots[${index}].name`}
              label="Name your Snapshot:"
              placeholder="e.g. Homepage"
              maxLength={140}
              value={snapshotNameValue}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              validationIcon={
                snapshotNameTouched && snapshotNameError
                  ? false
                  : snapshotNameTouched && !snapshotNameError
                  ? true
                  : null
              }
              className="!h-[50px] !pr-10"
              error={snapshotNameTouched && snapshotNameError ? snapshotNameError : null}
            />
            <div className="text-caption absolute right-1 top-[26px] font-semibold">
              {140 - snapshotNameValue.length}
            </div>
          </div>
          {snapshotsCount > 2 && (
            <div className="mt-2.5">
              <Button variant="warning" size="xs" onClick={() => removeSnapshot()}>
                Delete
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
