import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import { Spinner, Button } from '@crazyegginc/hatch';

import { useHasFeatureFlag, useSite } from '/src/hooks';
import { NoSurveysWallContent } from './NoSurveysWall';
import { addonSuggestionsListQuery, SITES_FOR_ADDONS } from '/src/features/addons/queries';
import { ADDON_TYPES } from '../constants';
import { inflect } from '/src/utils/string';
import { getSurveyQueryParams } from '/src/utils/url';
import { FEATURES } from '../../_global/constants';
import { WebP } from '/src/components/WebP';
import { SURVEY_QUESTION_TYPES, RATING_SUBTYPES } from '/src/features/addons/constants';

import { ReactComponent as SparklesIcon } from '@crazyegginc/hatch/dist/images/icon-sparkles-filled.svg';
import { ReactComponent as DuplicateIcon } from '@crazyegginc/hatch/dist/images/icon-web-pages-outline.svg';
import { ReactComponent as EditIcon } from '@crazyegginc/hatch/dist/images/icon-pencil-filled.svg';

import SurveyMultiPNG from '@crazyegginc/hatch/dist/images/illustration-survey-multi.png';
import SurveyMultiWebP from '@crazyegginc/hatch/dist/images/illustration-survey-multi.webp';
import SurveyNumericalPNG from '@crazyegginc/hatch/dist/images/illustration-survey-numerical.png';
import SurveyNumericalWebP from '@crazyegginc/hatch/dist/images/illustration-survey-numerical.webp';
import SurveyRatingPNG from '@crazyegginc/hatch/dist/images/illustration-survey-rating.png';
import SurveyRatingWebP from '@crazyegginc/hatch/dist/images/illustration-survey-rating.webp';
import SurveySmileyPNG from '@crazyegginc/hatch/dist/images/illustration-survey-smiley.png';
import SurveySmileyWebP from '@crazyegginc/hatch/dist/images/illustration-survey-smiley.webp';
import SurveyTextPNG from '@crazyegginc/hatch/dist/images/illustration-survey-text.png';
import SurveyTextWebP from '@crazyegginc/hatch/dist/images/illustration-survey-text.webp';

export function SurveySuggestions({ mini }) {
  const hasSuggestionsFlag = useHasFeatureFlag('shell-addon-suggestions');
  const { selectedSite, selectSite, sites } = useSite({ dontSelectSite: true, sitesQuery: SITES_FOR_ADDONS });

  useEffect(() => {
    if (!selectedSite?.id) selectSite(sites?.[0], true);
  }, [selectSite, sites, selectedSite]);

  const { data, isFetching } = useQuery({
    ...addonSuggestionsListQuery({
      siteId: selectedSite?.id,
      limit: 3,
      type: ADDON_TYPES.SURVEY,
    }),
    enabled: Boolean(hasSuggestionsFlag && selectedSite?.id),
  });

  if (!selectedSite?.id) return null;

  if (isFetching && !data) {
    return (
      <div className="mt-16 flex w-full items-center justify-center">
        <Spinner />
        <div className="ml-2.5">Loading...</div>
      </div>
    );
  }

  if (!hasSuggestionsFlag || (!isFetching && data.addonSuggestionsList.length === 0)) {
    return <NoSurveysWallContent mini={mini} />;
  }

  const suggestions = data.addonSuggestionsList;

  return (
    <div className="mt-20 flex flex-col items-center justify-start">
      <h1 className="text-header-2">Create your first Survey!</h1>
      <div className="text-body-2 mt-4 max-w-xs px-2 text-center">
        Start with a <SparklesIcon className="inline-block h-4 w-4 fill-current text-lavender-500" /> recommended survey
        customized for {selectedSite.name}.
      </div>
      <div className="mt-7 flex space-x-5">
        {suggestions.map((suggestion) => (
          <SurveySuggestion key={suggestion.id} suggestion={suggestion} />
        ))}
      </div>
      <div className="item-center mt-12 flex space-x-2.5">
        <Button
          component={Link}
          variant="secondary"
          to={{ pathname: '/addons/surveys/new', search: getSurveyQueryParams() }}
        >
          <EditIcon className="mr-2.5 h-3.5 w-3.5 fill-current" />
          Start from scratch
        </Button>
        <Button
          component={Link}
          variant="secondary"
          to={{ pathname: `/addons/templates`, search: getSurveyQueryParams({ type: FEATURES.SURVEYS }) }}
        >
          <DuplicateIcon className="mr-2.5 h-3.5 w-3.5 fill-current" />
          Browses templates
        </Button>
      </div>
    </div>
  );
}

function SurveySuggestion({ suggestion }) {
  const data = JSON.parse(suggestion.data);
  const q = data.questions[0];

  let width, png, webp;
  switch (q.type) {
    case SURVEY_QUESTION_TYPES.MULTI_CHOICE:
      width = 50;
      png = SurveyMultiPNG;
      webp = SurveyMultiWebP;
      break;
    case SURVEY_QUESTION_TYPES.TEXT_ENTRY:
      width = 50;
      png = SurveyTextPNG;
      webp = SurveyTextWebP;
      break;
    case SURVEY_QUESTION_TYPES.RATING:
      switch (q.subtype) {
        case RATING_SUBTYPES.STARS:
          width = 60;
          png = SurveyRatingPNG;
          webp = SurveyRatingWebP;
          break;
        case RATING_SUBTYPES.NPS:
        case RATING_SUBTYPES.NUMERICAL:
          width = 60;
          png = SurveyNumericalPNG;
          webp = SurveyNumericalWebP;
          break;
        case RATING_SUBTYPES.SMILEY:
          width = 60;
          png = SurveySmileyPNG;
          webp = SurveySmileyWebP;
          break;
      }
      break;
  }

  return (
    <section className="relative flex w-80 flex-col items-center rounded-[5px] bg-white px-5 py-6 shadow-md">
      <div className="absolute -top-3 left-1/2 -translate-x-1/2">
        <WebP imageClassName="shadow-md" webp={webp} fallback={png} width={width} alt="survey illustration" />
      </div>
      <div className="text-header-3 mt-7 w-full text-center font-bold leading-tight">{suggestion.title}</div>
      <div className="text-body-5 mt-2 self-start">First question:</div>
      <div className="text-header-4 mb-2 mt-1 w-full rounded-md bg-lavender-500/10 p-2.5 leading-tight">
        {q.title}
        {q.subtext ? <div className="text-body-2 mt-2">{q.subtext}</div> : null}
      </div>
      <div className="text-body-2 mb-5 self-start">
        {data.questions.length > 1 ? (
          <>
            + {data.questions.length - 1} more {inflect('question', data.questions.length - 1)}
          </>
        ) : null}
      </div>

      <Link
        to={{ pathname: `/addons/surveys/${suggestion.id}/newFromSuggestion`, search: getSurveyQueryParams() }}
        className="mt-auto flex items-center px-2 text-sm font-semibold text-lavender-500"
      >
        <SparklesIcon className="mr-2 h-4 w-4 fill-current" />
        Create this survey
      </Link>
    </section>
  );
}
