import { useMemo, useRef, useCallback, useState } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { ReactTable, Panel } from '@crazyegginc/hatch';

import { useAuthContext } from '/src/hooks';
import { paymentTransactionListQuery } from '../queries';
import { SORT_ORDER_TYPES } from '../../_global/constants';
import { camelToSnake, snakeToCamel } from '/src/utils/string';
import { formatDate } from '/src/utils/date';
import { round } from '/src/utils/math';
import { AppConfig } from '/src/AppConfig';
import { PAYMENT_TYPE_NAMES } from '../constants';

export function BillingHistory() {
  const tableRef = useRef(null);
  const [field, setField] = useState('CREATED_AT');
  const [sort, setSort] = useState(SORT_ORDER_TYPES.DESC);
  const { currentAccount } = useAuthContext();

  const { data, isFetching, hasNextPage, fetchNextPage, isInitialLoading } = useInfiniteQuery({
    ...paymentTransactionListQuery({
      limit: 25,
      field: field,
      sort: sort,
    }),
  });

  const transactions = useMemo(
    () =>
      data?.pages.reduce((acc, page) => [...acc, ...(page.paymentTransactionList?.paymentTransactions ?? [])], []) ??
      [],
    [data],
  );

  const columns = useMemo(
    () => [
      {
        header: 'Date',
        accessorKey: 'createdAt',
        size: 60,
        meta: {
          align: 'center',
        },
        cell: ({ row }) => formatDate(row.original.createdAt),
      },
      {
        header: 'Plan',
        accessorKey: 'planName',
        size: 120,
        enableSorting: false,
        meta: {
          align: 'center',
          justify: 'left',
        },
        cell: ({ row }) => row.original.planName,
      },
      {
        header: 'Payment card',
        accessorKey: 'cardNumber',
        size: 120,
        meta: {
          align: 'center',
          justify: 'left',
        },
        cell: ({ row }) => row.original.cardNumber,
      },
      {
        header: 'Type',
        accessorKey: 'paymentType',
        size: 120,
        meta: {
          align: 'center',
          justify: 'left',
        },
        cell: ({ row }) => PAYMENT_TYPE_NAMES[row.original.paymentType],
      },
      {
        header: 'Amount',
        accessorKey: 'amount',
        size: 50,
        meta: {
          align: 'center',
          justify: 'right',
        },
        cell: ({ row }) =>
          row.original.amount == null || isNaN(row.original.amount)
            ? null
            : `$${round(row.original.amount, 2).toFixed(2)}`.replace(/^\$-/, '-$'),
      },
      {
        header: '',
        accessorKey: 'id',
        size: 120,
        enableSorting: false,
        meta: {
          align: 'center',
          justify: 'center',
        },
        cell: ({ row }) =>
          row.original.amount == null || isNaN(row.original.amount) ? null : (
            <div className="ml-5 flex items-center">
              <a
                href={`${AppConfig.legacyCoreBaseURL()}/receipt/${row.original.id}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-link font-normal"
              >
                HTML
              </a>
              <span className="whitespace-pre"> | </span>
              <a
                href={`${AppConfig.legacyCoreBaseURL()}/receipt/pdf/${row.original.id}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-link font-normal"
              >
                PDF
              </a>
            </div>
          ),
      },
    ],
    [],
  );

  const tableSortData = useMemo(() => {
    return {
      id: snakeToCamel(field),
      desc: sort === SORT_ORDER_TYPES.DESC,
    };
  }, [field, sort]);

  const onFetchData = useCallback(
    ({ sorting }) => {
      if (sorting) {
        const nextField = camelToSnake(sorting[0].id).toUpperCase();
        setField(nextField);
        const nextSort = sorting[0].desc ? SORT_ORDER_TYPES.DESC : SORT_ORDER_TYPES.ASC;
        setSort(nextSort);
      }
    },
    [setField, setSort],
  );

  return (
    <>
      <h2 className="text-header-3 mb-2.5 flex items-center">Billing history</h2>
      <ReactTable
        fetching={isInitialLoading}
        ref={{ tableRef }}
        rowPadding={false}
        columns={columns}
        data={transactions}
        onFetchData={onFetchData}
        enableSorting={true}
        sorting={tableSortData}
        useVirtualization={true}
        hasNextPage={hasNextPage}
        isNextPageLoading={isFetching}
        loadNextPage={fetchNextPage}
      />
      {currentAccount.isShopifyUser ? (
        <Panel>
          <div className="text-body-2">
            *Your billing history is managed through your Shopify store:{' '}
            <strong className="text-body-1">{currentAccount.shopifyDomain}</strong>. You can find your invoices{' '}
            <a
              class="text-link"
              href={`//${currentAccount.shopifyDomain}/admin/settings/billing`}
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
            .
          </div>
        </Panel>
      ) : null}
    </>
  );
}
