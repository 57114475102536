import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate, useParams, Navigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import classNames from 'classnames';
import { ReactTable, Tooltip, SkeletonLine, Button, IconButton, InfoBox } from '@crazyegginc/hatch';
import { fromUnixTime, getUnixTime, startOfDay } from 'date-fns';

import { DashHeader } from '/src/components/headers/DashHeader';
import { Breadcrumb, BreadcrumbItem } from '/src/components/Breadcrumb';
import { ActionButton } from '/src/features/goals/components/ActionButton';
import { DashboardPage } from '/src/components/Page';
import { RenameModal } from '../components/modals/RenameModal';

import { usePermissions, useAuthContext, useSite, useNotifications, useModal } from '/src/hooks';

import { formatDate } from '/src/utils/date';
import { flashElement, removeHash, isSharedResourceSubscriptionError } from '/src/utils';
import { getFeatureName } from '/src/features/_global/utils';
import { abTestTypes, AB_TEST_STATUSES } from '/src/features/ab-testing/constants';
import { FEATURES } from '/src/features/_global/constants';

import { ReactComponent as StatusRunningIcon } from '@crazyegginc/hatch/dist/images/icon-play-filled.svg';
import { ReactComponent as StatusPausedIcon } from '@crazyegginc/hatch/dist/images/icon-pause-filled.svg';
import { ReactComponent as StatusStoppedIcon } from '@crazyegginc/hatch/dist/images/icon-stop-outline.svg';
import { ReactComponent as StatusErrorIcon } from '@crazyegginc/hatch/dist/images/icon-cross-circle-filled.svg';
import { ReactComponent as ArrowIcon } from '@crazyegginc/hatch/dist/images/icon-arrow.svg';
import { ReactComponent as EditIcon } from '@crazyegginc/hatch/dist/images/icon-pencil-filled.svg';

import { DateRangePicker } from '@crazyegginc/hatch';

import { getConvertedDateRange } from '/src/utils/date';
import { goalSelectedDatesAtom } from '/src/features/goals/store';
import { SPECIAL_DATE_RANGES } from '/src/features/_global/constants';

// CONSTANTS
const PER_PAGE = 10;

// QUERIES
import { goalDetailQuery, goalCountsQuery, goalSessionsCountQuery } from '/src/features/goals/queries';

// COMPONENTS
import { SEO } from '/src/components/SEO';
import { SitePickerBar } from '/src/components/site-picker';
import { GoalDetailCard } from '/src/features/goals/components/DetailCard';
import { DistributionChart } from '/src/features/goals/components/DistributionChart';
import { abTestDuration } from '/src/features/ab-testing/utils';
import { PaginationControls } from '/src/components/PaginationControls';

// CONTENT :START
// NOTE: AT THE MOMENT USING SITES LENGTH TO SHOW THE CONTENT
function GoalDetail({ goal = null, isFetching = false, error = null }) {
  const { isSharing } = useAuthContext();
  const notifications = useNotifications();
  const navigate = useNavigate();
  const { id } = useParams();
  const [page, setPage] = useState(1);
  const permissions = usePermissions();
  const canVisitGoals = permissions.can('navigate', FEATURES.GOALS).allowed;
  const [selection, setSelection] = useAtom(goalSelectedDatesAtom);

  const abTests = goal?.abTests ?? [];
  const paginatedAbTests = abTests.slice((page - 1) * PER_PAGE, page * PER_PAGE);

  const { data: countsData, isFetching: fetchingCounts } = useQuery({
    ...goalCountsQuery({
      id: Number(id),
      presetDate: selection.special,
      startDate: selection.startDate,
      endDate: selection.endDate,
    }),
    networkMode: 'always',
    cacheTime: 0,
  });

  const { data: goalSessionsCountData, isFetching: fetchingSessionsCount } = useQuery({
    ...goalSessionsCountQuery({
      id: Number(id),
      presetDate: selection.special,
      startDate: selection.startDate,
      endDate: selection.endDate,
    }),
    enabled: !isSharing,
    networkMode: 'always',
    cacheTime: 0,
  });

  useEffect(() => {
    if (error && error.message.match(/Goal not found/)) {
      notifications.error({ content: `No matching goal found`, timeout: 3000, skipHoneybadger: true });
      navigate(canVisitGoals ? '/goals' : '/not-found', { replace: true });
    }
  }, [error, navigate, notifications, canVisitGoals]);

  const canViewGoal = permissions.can('view', goal).allowed;

  useEffect(() => {
    if (goal && !canViewGoal) {
      notifications.error({
        content: 'You do not have permission to view that goal.',
        timeout: 3000,
        skipHoneybadger: true,
      });
      navigate('/goals', { replace: true });
    }
  }, [canViewGoal, goal, navigate, notifications]);

  const counts = countsData?.goalCounts ?? null;
  const sessionsCount = goalSessionsCountData?.goalSessionsCount ?? 0;
  const abTestsCount = goal?.abTestsCountTotal ?? 0;

  const showABTestTable = abTestsCount > 0 && abTests.length > 0;

  useEffect(() => {
    if (showABTestTable && window.location.hash === '#ab-tests') {
      setTimeout(() => {
        const el = document.querySelector('#ab-tests > div');
        flashElement(el);
        removeHash();
      }, 1000);
    }
  }, [showABTestTable]);

  const setGoalDateRange = useCallback(
    (value) => {
      // we need to do a comparison to avoid the DateRangePicker
      // triggering an infinite rerender, since it calls the `setDateRange`
      // function from inside a useEffect
      if (
        (value.start_date && value.start_date !== selection.startDate) ||
        (value.end_date && value.end_date !== selection.endDate)
      ) {
        setSelection({
          startDate: getUnixTime(value.start_date),
          endDate: getUnixTime(value.end_date),
        });
      }
    },
    [selection.endDate, selection.startDate, setSelection],
  );

  const setSpecialRange = useCallback(
    ({ special }) => {
      if (special && special !== selection.special) {
        const { startDate, endDate } = getConvertedDateRange({ special });
        setSelection({ special, startDate, endDate });
      }
    },
    [selection.special, setSelection],
  );

  useEffect(() => {
    setSpecialRange({ special: SPECIAL_DATE_RANGES.LAST_7_DAYS });
  }, [id]); // eslint-disable-line

  return (
    <div className="mt-10 flex w-full flex-col px-10">
      <div className="mb-3.75 flex items-center justify-between">
        <h2 className="text-header-3">Goal details</h2>

        {goal?.createdAt ? (
          <DateRangePicker
            startDate={fromUnixTime(selection.startDate)}
            endDate={fromUnixTime(selection.endDate)}
            lowerBoundary={startOfDay(fromUnixTime(goal.createdAt))}
            upperBoundary={new Date()}
            setCommonDateRange={setSpecialRange}
            size="base"
            special={selection.special}
            tooltipOptions={{}}
            setDateRange={setGoalDateRange}
            showCommonRanges={true}
          />
        ) : null}
      </div>
      <GoalDetailCard
        goal={goal}
        abTests={abTests}
        isFetching={isFetching}
        abTestsCount={abTestsCount}
        counts={counts}
        sessionsCount={sessionsCount}
        isFetchingCounts={fetchingCounts}
        isFetchingSessionsCount={fetchingSessionsCount}
      />

      {showABTestTable ? (
        <>
          <div className="mb-5 mt-10 flex items-center">
            <h2 className="text-header-3 mr-4">A/B Tests using this Goal</h2>
            <InfoBox
              header="Why don’t A/B Test conversions match Total conversions?"
              body="One visitor may successfuly complete this goal on multiple tests, however they will only count for one conversion under the total conversion count."
            />
          </div>
          <GoalAbTestsTable loading={isFetching} abTests={paginatedAbTests} />
          <div className="mt-4">
            <PaginationControls
              currentPage={page}
              perPage={PER_PAGE}
              total={goal?.abTestsCount}
              setPage={(page) => setPage(page)}
            />
          </div>
        </>
      ) : null}
      <span
        className={classNames('h-10 w-full', {
          'mb-10 border-b border-dashed border-b-mystic-500': abTestsCount === 0,
        })}
      >
        &nbsp;
      </span>
      <DistributionChart goal={goal} counts={counts} isFetchingCounts={fetchingCounts} />
    </div>
  );
}

// ALL COMPONENTS :START
// eslint-disable-next-line no-restricted-syntax
export default function GoalDetailsPage() {
  const { isSharing, sharedResource } = useAuthContext();
  const { id } = useParams();
  const permissions = usePermissions();
  const canVisitDashboard = permissions.can('navigate', FEATURES.GOALS).allowed;
  const canEditGoals = permissions.can('edit', FEATURES.GOALS).allowed;
  const modal = useModal();
  const [selection] = useAtom(goalSelectedDatesAtom);

  const { sites, loadingSites, selectSite } = useSite({
    dontSelectSite: true,
    sitesQueryProps: { enabled: !isSharing },
  });

  const { data, isFetching, error, isInitialLoading } = useQuery({
    ...goalDetailQuery({
      id: Number(id),
      presetDate: selection.special,
      startDate: selection.startDate,
      endDate: selection.endDate,
    }),
    keepPreviousData: true,
  });

  useEffect(() => {
    if (data?.goalDetail?.siteId) {
      selectSite(data.goalDetail.siteId);
    }
  }, [data, selectSite]);

  const goal = data?.goalDetail ?? null;

  const pickerSite = isSharing
    ? { name: sharedResource?.resource?.siteName, id: sharedResource?.resource?.siteId }
    : sites?.find((s) => s.id === goal?.siteId);

  if (error && isSharedResourceSubscriptionError(error)) {
    return <Navigate replace to="/not-found" />;
  }

  return (
    <DashboardPage>
      <SEO title={getFeatureName(FEATURES.GOALS)} />
      <DashHeader
        titleComponent={
          <Breadcrumb>
            {!isSharing && (
              <BreadcrumbItem active={false} to="/goals">
                Goals
              </BreadcrumbItem>
            )}
            <BreadcrumbItem active={true}>
              <div className="mr-5 flex items-center space-x-1">
                {goal?.name ? (
                  <>
                    <span className="truncate">{goal.name}</span>
                    {goal.permissions.canEdit ? (
                      <Tooltip placement="bottom" tooltipContent="Rename A/B Test">
                        <IconButton
                          icon={<EditIcon className="h-4 w-4 fill-current" />}
                          className="h-full shrink-0 text-malibu-500 hover:text-dodger-blue-500"
                          onClick={() => {
                            modal.show(<RenameModal goal={goal} />);
                          }}
                          label="edit AB test name"
                        />
                      </Tooltip>
                    ) : null}
                  </>
                ) : isInitialLoading ? (
                  <SkeletonLine width="300px" height="16px" />
                ) : null}
              </div>
            </BreadcrumbItem>
          </Breadcrumb>
        }
        actionButton={
          <div className="flex flex-shrink-0 items-center space-x-2">
            {canVisitDashboard ? (
              <Button
                component={Link}
                variant="ghost-primary"
                to={{
                  pathname: `/goals`,
                }}
                size="xl"
                className="text-center leading-none"
              >
                <ArrowIcon className="mr-2 h-3 w-3 shrink-0 rotate-180 fill-current" />
                Back to all Goals
              </Button>
            ) : null}
            {canEditGoals ? <ActionButton goal={goal} abTests={goal?.abTests ?? []} redirectOnDelete="/goals" /> : null}
          </div>
        }
      />
      <SitePickerBar
        sites={pickerSite ? [pickerSite] : []}
        selectedSite={pickerSite}
        loading={isFetching || (loadingSites && !isSharing)}
        pageDisplayName="Goal Details"
      />

      <GoalDetail goal={goal} isFetching={isFetching} error={error} />
    </DashboardPage>
  );
}

function Dot() {
  return <span className="text-caption block h-2 w-2 rounded-full bg-current">&nbsp;</span>;
}

function RenderStatusCell(status) {
  let Icon, text;
  let className = '';

  switch (status) {
    case AB_TEST_STATUSES.RUNNING:
      Icon = StatusRunningIcon;
      text = 'Running';
      className = 'text-lima-500';
      break;
    case AB_TEST_STATUSES.PAUSED:
      Icon = StatusPausedIcon;
      text = 'Paused';
      className = 'text-lynch-500';
      break;
    case AB_TEST_STATUSES.STOPPED:
      Icon = StatusStoppedIcon;
      text = 'Ended';
      className = 'text-california-500';
      break;
    case AB_TEST_STATUSES.DRAFT:
      Icon = Dot;
      text = 'Draft';
      className = 'text-caption text-sm';
      break;
    case AB_TEST_STATUSES.DELETED:
      Icon = Dot;
      text = 'Deleted';
      className = 'text-caption text-sm';
      break;
    default:
      Icon = StatusErrorIcon;
      text = 'Error';
      className = 'text-radical-red-500';
      break;
  }

  return (
    <div className={`flex items-center leading-none ${className}`}>
      <div
        className={classNames('mr-2.5 h-2.5 w-2.5 rounded', {
          'bg-california-500': status === AB_TEST_STATUSES.STOPPED,
        })}
      >
        <Icon className="h-2.5 w-2.5 fill-current" aria-label={`a/b test ${text.toLowerCase()}`} />
      </div>{' '}
      {text}
    </div>
  );
}

function GoalAbTestsTable({ abTests = [], loading = false }) {
  const permissions = usePermissions();
  const canCreateAbTests = permissions.can('create', FEATURES.AB_TESTING).allowed;
  const { isSharing } = useAuthContext();
  const tableRef = useRef(null);
  const columns = useMemo(
    () => [
      {
        header: () => {
          return <div className="pl-2.5">Name</div>;
        },
        accessorKey: 'name',
        size: 35,
        meta: {
          justify: 'left',
          align: 'center',
          padding: '0px 0px 0px 15px',
        },
        cell: ({ row }) => {
          if (
            !isSharing &&
            row.original.status !== AB_TEST_STATUSES.DELETED &&
            (row.original.status !== AB_TEST_STATUSES.DRAFT || canCreateAbTests)
          ) {
            return (
              <Link
                to={{
                  pathname:
                    row.original.status === AB_TEST_STATUSES.DRAFT
                      ? `/ab-tests/${row.original.id}/edit`
                      : `/ab-tests/${row.original.id}`,
                  search: `?back=${window.location.pathname}`,
                }}
                className="text-link mr-4 truncate"
                title={row.original.name}
              >
                {row.original.name}
              </Link>
            );
          }
          return (
            <span className="text-link mr-4 truncate !text-lynch-500 hover:!no-underline">{row.original.name}</span>
          );
        },
      },
      {
        header: 'URL',
        accessorKey: 'pageUrl',
        size: 35,
        meta: {
          justify: 'left',
          align: 'center',
        },
        cell: ({ row }) => {
          const { status, pageUrl } = row.original;

          return (
            <div className="flex w-full flex-wrap">
              <div
                className={classNames('line-clamp-2 w-full break-all leading-none', {
                  'text-lynch-500': status !== AB_TEST_STATUSES.RUNNING,
                })}
              >
                {pageUrl}
              </div>
            </div>
          );
        },
      },
      {
        header: 'Variants',
        size: 14,
        meta: {
          justify: 'center',
          align: 'center',
        },
        cell: ({ row }) => {
          const { status, variants, type, variantsCount, pageUrl } = row.original;

          return (
            <span className={status !== AB_TEST_STATUSES.RUNNING ? 'text-lynch-500' : null}>
              {variants.length > 1 && type === abTestTypes.SPLIT ? (
                <Tooltip
                  placement="bottom"
                  tooltipContent={
                    <div className="max-w-[225px]">
                      <ul className="ml-5 list-outside list-disc space-y-1 text-left text-xs marker:text-cadet-blue-500">
                        {variants.map((variant) => (
                          <li key={variant.id} className="mb-1.25 w-full break-all">
                            <div className="font-bold leading-tight">{variant.redirectUrl ?? pageUrl}</div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  }
                >
                  <span className="text-xs text-lynch-500 underline decoration-cadet-blue-500 decoration-dashed underline-offset-4">
                    {variantsCount || 0}
                  </span>
                </Tooltip>
              ) : (
                variantsCount || 0
              )}
            </span>
          );
        },
      },
      {
        header: 'Status',
        size: 14,
        meta: {
          justify: 'left',
          align: 'center',
        },
        cell: ({ row }) => RenderStatusCell(row.original.status),
      },
      {
        header: 'Duration',
        size: 14,
        meta: {
          justify: 'left',
          align: 'center',
        },
        cell: ({ row }) => {
          const duration = abTestDuration(row.original);
          return (
            <Tooltip
              tooltipContent={
                <div className="flex flex-col">
                  <span className="font-normal">
                    Created <span className="font-semibold">{formatDate(row.original.createdAt)}</span>
                  </span>
                  {row.original.stoppedAt ? (
                    <span className="font-normal">
                      Ended <span className="font-semibold">{formatDate(row.original.stoppedAt)}</span>
                    </span>
                  ) : null}
                </div>
              }
            >
              <span
                className={classNames('underline decoration-cadet-blue-500 decoration-dashed underline-offset-4', {
                  'text-lynch-500': row.original.status !== AB_TEST_STATUSES.RUNNING,
                })}
              >
                {duration.value}
              </span>
            </Tooltip>
          );
        },
      },
      {
        header: 'Visits',
        accessorKey: 'totalVisitors',
        size: 12,
        meta: {
          justify: 'center',
          align: 'center',
        },
        cell: ({ row }) => (
          <span className={row.original.status !== AB_TEST_STATUSES.RUNNING ? 'text-lynch-500' : null}>
            {row.original.totalVisitors || 0}
          </span>
        ),
      },
      {
        header: 'Conversions',
        size: 15,
        meta: {
          justify: 'center',
          align: 'center',
          padding: '0px 15px 0px 0px',
        },
        cell: ({ row }) => {
          const totalConversions = row.original.totalConversions || 0;
          return (
            <Tooltip
              show={row.original.variants.length > 1}
              tooltipContent={
                <div className="flex flex-col">
                  {row.original.variants.map((variant) => {
                    return (
                      <div key={`${row.original.id}_${variant.id}_variant`} className="flex justify-between">
                        <div className="flex flex-1 justify-start">
                          <span className="text-xs">{variant.variantName}</span>
                        </div>
                        <span className="ml-8 text-xs font-semibold">{variant.results.conversions || 0}</span>
                      </div>
                    );
                  })}
                </div>
              }
            >
              <span
                className={classNames('underline decoration-cadet-blue-500 decoration-dashed underline-offset-4', {
                  'text-lynch-500': row.original.status !== AB_TEST_STATUSES.RUNNING,
                })}
              >
                {totalConversions}
              </span>
            </Tooltip>
          );
        },
      },
    ],
    [isSharing, canCreateAbTests],
  );

  return (
    <div id="ab-tests" className={classNames()}>
      <ReactTable
        ref={{ tableRef }}
        useVirtualization={false}
        fetching={loading}
        rowPadding={true}
        rowHeight={64}
        headerHeight={70}
        columns={columns}
        data={abTests}
        enableSorting={false}
        rowCustomClassname={(row) => {
          return classNames({
            '!text-lynch-500': row && row.original.status === AB_TEST_STATUSES.DELETED,
          });
        }}
      />
    </div>
  );
}
